import { LibraryRecord } from "@/api/types/library";
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { Button } from "@/components/ui/button";
import { ArrowLeft, Check, Loader } from "lucide-react";
import { GET_TREE } from "@/graphql/queries";
import { useQuery } from "@apollo/client";
import { useGetCodebaseRootQuery } from "@/api/api";
import { FlatNode, buildTreeData } from "./TreeUtils";
import DriverTree from "./Tree/DriverTree";

function reducer(state: string[], newState: string[]): string[] {
  return newState;
}

interface DriverTunerProps {
  setTunerOpen: (open: boolean) => void;
  handleAddAsset: (asset: LibraryRecord) => void;
  handleRemoveAsset: (id: string) => void;
  tunerItem: LibraryRecord | null;
  handleTunerSubmit: (checked: string[]) => void;
}

export interface DriverTreeNode {
  kind: string;
  children?: DriverTreeNode[];
  name: string;
  id: string;
}

const DriverTuner: React.FC<DriverTunerProps> = ({
  setTunerOpen,
  handleAddAsset,
  handleRemoveAsset,
  tunerItem,
  handleTunerSubmit,
}) => {
  const [checked, setChecked] = useReducer(reducer, []);

  const { loading, error, data } = useQuery(GET_TREE, {
    variables: {
      codebaseId: tunerItem?.codebase_id,
    },
    fetchPolicy: "cache-and-network",
    skip: !tunerItem,
  });

  const { data: codebaseRoot, isLoading: codebaseRootLoading } =
    useGetCodebaseRootQuery(
      { contentId: data?.tree[0]?.id },
      { skip: !data?.tree?.[0]?.id }
    );
  const isLoading = codebaseRootLoading || loading;
  const noTree = !data?.tree?.[0];

  const submitTuner = () => {
    if (!tunerItem) return;
    if (!codebaseRoot) return;
    // remove previous children from the codebase
    const newTuner = { ...tunerItem, children: [] };
    handleRemoveAsset(tunerItem.id);
    // add the codebase back without children
    handleAddAsset(newTuner);

    // remove the deselected children from the assets list
    tunerItem.children
      ?.filter((id) => !checked.includes(id))
      .forEach((id) => handleRemoveAsset(id));

    // add the newly selected children to the assets list
    checked
      .map((id) => ({
        ...newTuner,
        id: id,
        content_type_name: "codebase-",
        status: codebaseRoot.status,
        codebase_id: tunerItem?.codebase_id,
      }))
      .forEach((asset) => handleAddAsset(asset as LibraryRecord));

    handleTunerSubmit(checked);
  };

  const buildTree = useMemo(() => {
    return (flatTree: FlatNode[], codebaseId: string) =>
      buildTreeData(flatTree, codebaseId);
  }, [data, codebaseRoot]);

  const getSelectionCount = (): string | number | false => {
    if (isLoading) return false;
    const firstChecked = checked?.[0];
    const entireCodebase =
      firstChecked === codebaseRoot?.id || firstChecked === tunerItem?.id;
    if (entireCodebase) {
      return "Codebase";
    }
    if (checked.length > 0) {
      return checked.length;
    }
    return false;
  };

  const treeData = buildTree(data?.tree ?? [], codebaseRoot?.id ?? "");
  const checkedCount = getSelectionCount();

  return (
    <div className="driver-tuner">
      <Button
        className="mb-2"
        variant="secondary"
        size="sm"
        onClick={() => setTunerOpen(false)}
      >
        <ArrowLeft className="w-4 h-4 mr-2" />
        Back
      </Button>
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium pb-2 pl-1">Driver Tuning</h3>
        {checkedCount && (
          <span className="text-muted-foreground">{checkedCount} selected</span>
        )}
      </div>
      <div className="relative react-suite-component-wrapper h-[346px]">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-background bg-opacity-75 z-10">
            <div className="loader">
              <Loader
                className="animate-spin"
                size={32}
                data-testid="loading-indicator"
              />
            </div>
          </div>
        )}
        {noTree && <div>No tree available for this codebase</div>}
        {treeData && treeData?.id && (
          <DriverTree
            data={treeData}
            setChecked={setChecked}
            defaultValues={tunerItem?.children}
          />
        )}
      </div>
      <Button
        disabled={checked.length === 0 || codebaseRootLoading}
        onClick={submitTuner}
        className="w-full mt-2 self-end"
      >
        <Check className="w-4 h-4 mr-2" />
        Accept Tuning
      </Button>
    </div>
  );
};

export default DriverTuner;
