import React, { useState } from "react";
import { ArrowDownUp, Loader, SearchIcon } from "lucide-react";
import { FetchLibraryParams, LibraryRecord } from "@/api/types/library";
import { Button } from "@/components/ui/button";
import {
  contentTypeLabels,
  defaultScopeContentTypes,
} from "./useUserDefinedScopeData";
import { Divider } from "../BlockEditor/ui/PopoverMenu";
import { ContentTypeButtons } from "./AssetSearchButtons";
import { useGetLibraryQuery, useGetTagsQuery } from "@/api/api";
import AssetItem from "./AssetItem";
import CollectionItem from "./CollectionItem";
import { isEqual } from "lodash";
import AssetItemSkeleton from "./AssetItemSkeleton";
export const assetListLimit = 6;

interface AssetListProps {
  selectedAssets: LibraryRecord[];
  handleAddAsset: (asset: LibraryRecord) => void;
  handleRemoveAsset: (assetId: string) => void;
}

const AssetList: React.FC<AssetListProps> = ({
  selectedAssets,
  handleAddAsset,
  handleRemoveAsset,
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const libraryQueryParams: FetchLibraryParams = {
    text: searchText,
    limit: assetListLimit,
    offset: 0,
    content_type_name: defaultScopeContentTypes,
    sort_direction: "DESC",
    sort_by: "updated_at",
    tag_id: [],
  };
  const tagsQueryParams: TagGetParams = {
    type: "collection",
    limit: assetListLimit,
    offset: 0,
    name: searchText,
  };
  const [params, setParams] = useState<FetchLibraryParams>(libraryQueryParams);
  const [collectionParams, setCollectionParams] =
    useState<TagGetParams>(tagsQueryParams);
  const getLibraryProps = useGetLibraryQuery(params);

  const {
    data: libraryData,
    isLoading: isLoadingLibrary,
    isFetching: isFetchingLibrary,
    error: libraryError,
  } = getLibraryProps;

  const tagsQueryOptions = { skip: false };

  const getCollectionsProps = useGetTagsQuery(
    collectionParams,
    tagsQueryOptions
  );
  const {
    data: collectionsData,
    isFetching: isTagsFetching,
    isLoading: isTagsLoading,
    error: getTagsError,
  } = getCollectionsProps;

  const assets = libraryData?.results ?? [];
  const collections = collectionsData?.results ?? [];
  const showLoading =
    isLoadingLibrary || isTagsLoading || isFetchingLibrary || isTagsFetching;

  const loadMoreAssets = (direction: "increment" | "decrement") => {
    if (isCollection) {
      setCollectionParams(
        (prevParams: TagGetParams): TagGetParams => ({
          ...prevParams,
          offset:
            direction === "increment"
              ? prevParams.offset + assetListLimit
              : Math.max(prevParams.offset - assetListLimit, 0),
        })
      );
    } else if (isCodebaseOrSupplemental) {
      setParams(
        // @ts-ignore
        (prevParams: FetchLibraryParams): FetchLibraryParams => ({
          ...prevParams,
          offset:
            direction === "increment"
              ? prevParams.offset + assetListLimit
              : Math.max(prevParams.offset - assetListLimit, 0),
        })
      );
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setParams({
      ...params,
      text: e.target.value ?? "",
      offset: 0,
    });
    setCollectionParams({
      ...collectionParams,
      name: e.target.value ?? "",
      offset: 0,
    });
  };

  const mostRecentCollections = collections.map((c) => {
    const lib: LibraryRecord = {
      id: c.id,
      organization_id: c.organization_id,
      content_type_id: "content_type_id",
      content_type_name: "collection",
      workspace_id: "workspace_id",
      workspace_name: "workspace_name",
      content_name: c.name,
      codebase_name: "codebase_name",
      codebase_id: "codebase_id",
      source_content_id: "source_content_id",
      relative_path: "relative_path",
      content: "content",
      misc_metadata: {},
      status: "status",
      tags: [],
      created_at: c.created_at,
      updated_at: c.updated_at,
      order: 0,
    };
    return lib;
  });
  const firstCollections = mostRecentCollections
    .filter((c) =>
      searchText !== ""
        ? c.content_name.toLowerCase().includes(searchText.toLowerCase())
        : true
    )
    .slice(0, 3);
  const noAssets =
    libraryData?.results?.length === 0 && mostRecentCollections.length === 0;
  const mostRecent = [...firstCollections, ...assets];
  const collectionCount = collectionsData?.count ?? 0;
  const libraryCount = libraryData?.count ?? 0;
  const isLastCollection =
    collectionCount <= collectionParams.limit + collectionParams.offset;
  const isLastLibrary = libraryCount <= params.limit + params.offset;
  const isMostRecent = isEqual(
    params.content_type_name,
    defaultScopeContentTypes
  );
  const isCollection = isEqual(params.content_type_name, ["collection"]);
  const isCodebaseOrSupplemental =
    isEqual(params.content_type_name, ["codebase"]) ||
    isEqual(params.content_type_name, ["supplemental-document"]);

  const mostRecentAssets = [...firstCollections, ...assets].sort(
    (a, b) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
  );

  const isAdded = (asset: LibraryRecord) => {
    if (asset.content_type_name === "supplemental-document") {
      return selectedAssets.some((selected) => selected.id === asset.id);
    }
    return selectedAssets.some(
      (selected) =>
        selected.id === asset.id || selected.codebase_id === asset.codebase_id
    );
  };

  return (
    <>
      <div className="flex items-center p-2 mt-1">
        <SearchIcon className="w-4 h-4 ml-2 text-muted-foreground" />
        <input
          type="text"
          placeholder="Search"
          className="w-full resize-none bg-transparent focus:outline-none focus:border-none outline-none border-none ring-0 focus:ring-0 rounded-lg placeholder:text-muted-foreground text-primary"
          value={params.text}
          onChange={handleSearchChange}
        />
      </div>
      <Divider />

      <div className="flex space-x-1 p-3">
        <ContentTypeButtons
          params={params}
          setParams={setParams}
          setCollectionParams={setCollectionParams}
          defaultContentTypeNames={defaultScopeContentTypes}
        />
      </div>
      <div className="relative h-full max-h-[372px] overflow-y-scroll overflow-x-hidden">
        <div className="p-4 pt-2 w-full h-full">
          {showLoading && (
            <div className="flex flex-col space-y-2 pt-8 w-full">
              {Array.from({ length: assetListLimit }).map((_, index) => (
                <AssetItemSkeleton key={index} />
              ))}
            </div>
          )}
          {!showLoading && (
            <div className="min-h-[300px]">
              {noAssets && (
                <div className="flex items-center justify-center mt-12 text-muted-foreground">
                  No assets found, try adjusting your search.
                </div>
              )}
              {isMostRecent ? (
                <div>
                  {mostRecentAssets.map((asset) => {
                    return asset.content_type_name === "collection" ? (
                      <CollectionItem
                        key={asset.id}
                        asset={asset}
                        isAdded={isAdded(asset)}
                        handleAddAsset={handleAddAsset}
                        handleRemoveAsset={handleRemoveAsset}
                      />
                    ) : (
                      <AssetItem
                        key={asset.id}
                        asset={asset}
                        isAdded={isAdded(asset)}
                        handleAddAsset={handleAddAsset}
                        handleRemoveAsset={handleRemoveAsset}
                      />
                    );
                  })}
                </div>
              ) : (
                Object.entries(contentTypeLabels).map(([type, label]) => {
                  const records = isCollection
                    ? mostRecentCollections
                    : isMostRecent
                      ? mostRecent
                      : assets;
                  const filteredAssets = records.filter(
                    (record) => record.content_type_name === type
                  );

                  if (filteredAssets.length === 0) return null;
                  return (
                    <div key={type}>
                      <div className="flex justify-between items-center pb-2">
                        <div className="text-xs font-medium text-muted-foreground">
                          {label.plural}
                        </div>
                        <div className="text-xs font-medium text-muted-foreground flex items-center">
                          <ArrowDownUp className="mr-1 w-3 h-3" />
                          Most recent
                        </div>
                      </div>
                      {filteredAssets.map((asset) => {
                        return asset.content_type_name === "collection" ? (
                          <CollectionItem
                            key={asset.id}
                            asset={asset}
                            isAdded={isAdded(asset)}
                            handleAddAsset={handleAddAsset}
                            handleRemoveAsset={handleRemoveAsset}
                          />
                        ) : (
                          <AssetItem
                            key={asset.id}
                            asset={asset}
                            isAdded={isAdded(asset)}
                            handleAddAsset={handleAddAsset}
                            handleRemoveAsset={handleRemoveAsset}
                          />
                        );
                      })}
                    </div>
                  );
                })
              )}
            </div>
          )}
          {!noAssets && !isMostRecent && (
            <div className="absolute bottom-1 left-0 right-0 flex justify-center gap-2 p-2 rounded-lg bg-background">
              <Button
                size="sm"
                variant="ghost"
                leadingIcon="ChevronLeftIcon"
                onClick={() => loadMoreAssets("decrement")}
                disabled={
                  isCollection
                    ? collectionParams.offset === 0 || showLoading
                    : params.offset === 0 || showLoading
                }
              >
                Previous
              </Button>
              <Button
                size="sm"
                trailingIcon="ChevronRightIcon"
                variant="ghost"
                disabled={
                  isCollection
                    ? isLastCollection ||
                      showLoading ||
                      collections.length < assetListLimit
                    : isLastLibrary ||
                      showLoading ||
                      assets.length < assetListLimit
                }
                onClick={() => loadMoreAssets("increment")}
              >
                Next
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AssetList;
