import React from "react";
import { Skeleton } from "@/components/ui/skeleton";

const AssetItemSkeleton: React.FC = () => {
  return (
    <div className="flex justify-between align-middle pb-1 w-full animate-pulse">
      <div className="flex truncate">
        <Skeleton className="w-4 h-4 rounded-full mr-2" />
        <Skeleton className="w-32 h-4 flex-1 rounded" />
      </div>
      <Skeleton className="w-14 h-[24px] rounded" />
    </div>
  );
};

export default AssetItemSkeleton;
