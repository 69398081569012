import React from "react";

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "quaternary"
  | "ghost";
export type ButtonSize = "medium" | "small" | "icon" | "iconSmall";

export type ButtonProps = {
  variant?: ButtonVariant;
  active?: boolean;
  activeClassname?: string;
  buttonSize?: ButtonSize;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      active,
      buttonSize = "medium",
      children,
      disabled,
      variant = "primary",
      className,
      activeClassname,
      ...rest
    },
    ref
  ) => {
    let buttonClassName =
      "flex group items-center justify-center border border-transparent gap-2 text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap";

    if (variant === "primary") {
      buttonClassName +=
        " text-white bg-black border-black dark:text-black dark:bg-white dark:border-white";
      if (!disabled && !active) {
        buttonClassName +=
          " hover:bg-neutral-800 active:bg-neutral-900 dark:hover:bg-neutral-200 dark:active:bg-neutral-300";
      }
      if (active) {
        buttonClassName += ` bg-neutral-900 dark:bg-neutral-300 ${activeClassname}`;
      }
    } else if (variant === "secondary") {
      buttonClassName += " text-foreground dark:text-white";
      if (!disabled && !active) {
        buttonClassName +=
          " hover:bg-neutral-100 active:bg-neutral-200 dark:hover:bg-neutral-900 dark:active:bg-neutral-800";
      }
      if (active) {
        buttonClassName += " bg-neutral-200 dark:bg-neutral-800";
      }
    } else if (variant === "tertiary") {
      buttonClassName +=
        " bg-neutral-50 text-foreground dark:bg-neutral-900 dark:text-white dark:border-neutral-900";
      if (!disabled && !active) {
        buttonClassName +=
          " hover:bg-neutral-100 active:bg-neutral-200 dark:hover:bg-neutral-800 dark:active:bg-neutral-700";
      }
      if (active) {
        buttonClassName += ` bg-neutral-200 dark:bg-neutral-800 ${activeClassname}`;
      }
    } else if (variant === "ghost") {
      buttonClassName +=
        " bg-transparent border-transparent text-muted-foreground dark:text-muted-foreground";
      if (!disabled && !active) {
        buttonClassName +=
          " hover:bg-black/5 hover:text-foreground active:bg-black/10 active:text-neutral-800 dark:hover:bg-white/10 dark:hover:text-neutral-300 dark:active:text-neutral-200";
      }
      if (active) {
        buttonClassName += ` bg-black/10 text-neutral-800 dark:bg-white/20 dark:text-neutral-200 ${activeClassname}`;
      }
    }

    if (buttonSize === "medium") {
      buttonClassName += " py-2 px-3";
    } else if (buttonSize === "small") {
      buttonClassName += " py-1 px-2";
    } else if (buttonSize === "icon") {
      buttonClassName += " w-8 h-8";
    } else if (buttonSize === "iconSmall") {
      buttonClassName += " w-6 h-6";
    }

    if (className) {
      buttonClassName += ` ${className}`;
    }

    return (
      <button
        ref={ref}
        disabled={disabled}
        className={buttonClassName.trim()}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = "Button";
