import mermaid from "mermaid";
import { v4 as uuidv4 } from "uuid";

export const isMermaidDocument = (text: string) => {
    const mermaidKeywords = [
        "architecture",
        "block",
        "c4Context",
        "c4Container",
        "c4Context",
        "c4Component",
        "c4Deployment",
        "c4Dynamic",
        "classDiagram",
        "erDiagram",
        "flowchart",
        "gantt",
        "gitGraph",
        "graph TB",
        "graph BT",
        "graph LR",
        "graph RL",
        "graph TD",
        "journey",
        "mindmap",
        "packet",
        "pie",
        "quadrantChart",
        "requirementDiagram",
        "sankey",
        "sequenceDiagram",
        "stateDiagram",
        "timeline",
        "xyChart",
        "zenuml",
        "xychart-beta",
    ];
    return mermaidKeywords.some((keyword) => text?.trim().startsWith(keyword));
};

const errorClasses = ["rounded-md", "border", "border-red-500", "p-4", "mt-2"];

export const renderMermaid = (code: string, node: HTMLElement, fullScreen?: boolean) => {
    const dom = document.createElement("div");
    const uuid = uuidv4();
    dom.id = `mermaid-${uuid}`;
    dom.style.width = "100%";
    dom.style.height = "100%";
    dom.style.display = "flex";
    dom.style.justifyContent = "center";
    dom.style.alignItems = "center";
    dom.setAttribute("spellcheck", "false");
    dom.setAttribute("data-testid", "mermaid-container");
    mermaid
        .render(dom.id, code)
        .then(({ svg, bindFunctions }) => {
            dom.innerHTML = svg;
            bindFunctions?.(dom);
            const svgElement = dom.querySelector('svg');
            if (svgElement && fullScreen) {
                Object.assign(svgElement.style, {
                    height: '80vh',
                    maxHeight: '80vh',
                    width: '100%',
                    maxWidth: '100%',
                    display: 'block'
                });
            }
            node.innerHTML = dom.outerHTML;
            node.classList.remove(...errorClasses);
        })
        .catch((reason) => {
            document.querySelector(`#d${dom.id}`)?.remove();
            node.classList.add(...errorClasses);
            node.innerHTML = reason;
        });
};

export const clearMermaid = (node: HTMLElement) => {
    node.querySelector('.mermaid-container')?.remove();
    node.classList.remove(...errorClasses);
    node.innerHTML = '';
};
