import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { ChooseTagColor, TagColorOption } from "../LibraryTable/ChooseTagColor";
import { useGetTagsQuery } from "@/api/api";

interface CollectionDetailsProps {
  isEditingCollection?: boolean;
  collectionId?: string;
  collectionName: string;
  setCollectionName: (collectionName: string) => void;
  collectionColor: string;
  setCollectionColor: (collectionColor: string) => void;
}

const CollectionDetails: React.FC<CollectionDetailsProps> = ({
  isEditingCollection,
  collectionId,
  collectionName,
  setCollectionName,
  collectionColor,
  setCollectionColor,
}) => {
  const [isChooseTagColorOpen, setIsChooseTagColorOpen] = useState(false);

  const tagsQueryParams: TagGetParams = {
    type: "collection",
    limit: 1000,
    offset: 0,
  };
  const getCollectionsProps = useGetTagsQuery(tagsQueryParams);
  const { data: collectionsData } = getCollectionsProps;

  const handleColorChange = (tag: TagColorOption) => {
    setIsChooseTagColorOpen(false);
    setCollectionColor(tag.color);
  };

  const collectionNameError = collectionsData?.results.some(
    (collection) =>
      collection.name === collectionName && collection.id !== collectionId
  );

  return (
    <div className="flex flex-row gap-2 w-full">
      <Button variant="outline" onClick={() => setIsChooseTagColorOpen(true)}>
        <span
          className="inline-block w-3 h-3 rounded-full"
          style={{ backgroundColor: collectionColor }}
        ></span>
      </Button>
      <div className="flex flex-col w-full">
        <input
          type="text"
          placeholder="Add a name for your collection"
          className={`w-full resize-none rounded-lg placeholder:text-muted-foreground text-primary bg-transparent text-sm ${
            collectionNameError
              ? "border-red-500 focus:border-red-500 focus:ring-red-500"
              : "focus:ring-primary focus:border-primary"
          }`}
          value={collectionName}
          onChange={(e) => setCollectionName(e.target.value)}
        />
        {/* <p className="text-red-500 text-sm mt-1">
          {collectionNameError && "Duplicate collection name"}
        </p> */}
      </div>
      <ChooseTagColor
        name={collectionName}
        open={isChooseTagColorOpen}
        setOpen={setIsChooseTagColorOpen}
        setInputValue={setCollectionName}
        callback={handleColorChange}
        isLoading={false}
      />
    </div>
  );
};

export default CollectionDetails;
