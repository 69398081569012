import React from "react";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Command, CommandItem, CommandList } from "@/components/ui/command";
import { CodeIcon, Rows2Icon, ScanEyeIcon } from "lucide-react";

export type MermaidViewOption = "code" | "preview" | "split";

interface MermaidViewOptionsProps {
  viewOptionsOpen: boolean;
  viewOptions: MermaidViewOption;
  setViewOptionsOpen: (open: boolean) => void;
  handleViewOptionSelect: (option: MermaidViewOption) => void;
}

const MermaidViewOptions: React.FC<MermaidViewOptionsProps> = ({
  viewOptionsOpen,
  setViewOptionsOpen,
  handleViewOptionSelect,
  viewOptions,
}) => (
  <Popover open={viewOptionsOpen} onOpenChange={setViewOptionsOpen}>
    <PopoverTrigger asChild>
      <Button className="dropdown-toggle">
        {viewOptions.charAt(0).toUpperCase() + viewOptions.slice(1)}
      </Button>
    </PopoverTrigger>
    <PopoverContent className="w-[180px] p-0">
      <Command>
        <CommandList>
          <CommandItem
            value="code"
            onSelect={() => handleViewOptionSelect("code")}
          >
            <CodeIcon className="w-4 h-4 mr-2" />
            Code
          </CommandItem>
          <CommandItem
            value="both"
            onSelect={() => handleViewOptionSelect("preview")}
          >
            <ScanEyeIcon className="w-4 h-4 mr-2" />
            Preview
          </CommandItem>
          <CommandItem
            value="split"
            onSelect={() => handleViewOptionSelect("split")}
          >
            <Rows2Icon className="w-4 h-4 mr-2" />
            Split
          </CommandItem>
        </CommandList>
      </Command>
    </PopoverContent>
  </Popover>
);

export default MermaidViewOptions;
