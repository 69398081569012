import { NodeViewProps } from "@tiptap/react";
import { Button } from "@/components/ui/button";
import { CornerDownLeft, Sparkles, TrashIcon, Maximize2 } from "lucide-react";
import { marked } from "marked";
import { DOMParser as ProseMirrorDOMParser } from "prosemirror-model";
import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog";

import { INSTRUCTIONS_CUSTOM_EVENT_REMOVE } from "../RunAllInstructions";
import ReferenceList from "./ReferenceList";
import useGetCallId from "./useGetCallId";
import { Label } from "@/components/ui/label";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import BlockEditorBasic from "@/components/BlockEditor/BlockEditorBasic";

type Props = {
  nodeViewProps: NodeViewProps;
};

const calculateRows = (text: string): number => {
  const lines = text.split("\n");
  return lines.reduce((total, line) => {
    return total + Math.ceil(line.length / 90) || 1;
  }, 0);
};

const Suggestion = (props: Props) => {
  const { nodeViewProps } = props;
  const { editor, node } = nodeViewProps;
  const [editedContent, setEditedContent] = useState(node.attrs.generatedText);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(editedContent);
  const nodeIdRef = useRef(node.attrs.identifier);

  useEffect(() => {
    if (node.attrs.identifier !== nodeIdRef.current) {
      // Node has changed, reset state
      setEditedContent(node.attrs.generatedText);
      setDialogContent(node.attrs.generatedText);
      nodeIdRef.current = node.attrs.identifier;
    }
  }, [node.attrs.identifier, node.attrs.generatedText]);

  const handleBlockEditorChange = useCallback(
    (newContent: string) => {
      setEditedContent(newContent);
      nodeViewProps.updateAttributes({
        generatedText: newContent,
      });
    },
    [nodeViewProps]
  );

  const handleDialogEditorChange = useCallback((newContent: string) => {
    setDialogContent(newContent);
  }, []);

  const handleDialogOpen = useCallback(() => {
    setDialogContent(editedContent);
    setIsDialogOpen(true);
  }, [editedContent]);

  const handleDialogClose = useCallback((open: boolean) => {
    if (!open) {
      setIsDialogOpen(false);
    }
  }, []);

  const insertAndCloseDialog = useCallback(() => {
    setEditedContent(dialogContent);
    nodeViewProps.updateAttributes({
      generatedText: dialogContent,
    });
    setIsDialogOpen(false);
  }, [dialogContent, nodeViewProps]);

  const insert = async (contentToInsert: string) => {
    const htmlContent = await marked(contentToInsert);
    const doc = ProseMirrorDOMParser.fromSchema(editor.schema).parse(
      new DOMParser().parseFromString(htmlContent, "text/html")
    );
    const jsonContent = doc.toJSON();
    const endPos = nodeViewProps.getPos() + node.nodeSize;
    nodeViewProps.deleteNode();
    const detail = {
      id: node.attrs.identifier,
    };
    document.dispatchEvent(
      new CustomEvent(INSTRUCTIONS_CUSTOM_EVENT_REMOVE, { detail })
    );

    editor
      .chain()
      .insertContentAt(endPos - 1, jsonContent)
      .setTextSelection({
        from: endPos - 1,
        to: endPos - 1 + (doc?.content?.size ?? 0),
      })
      .run();

    // Scroll to inserted content
    setTimeout(() => {
      const view = editor.view;
      const insertedPos = endPos - 1;
      const insertedCoords = view.coordsAtPos(insertedPos);
      const padding = 175;
      const scrollContainer =
        document.scrollingElement || document.documentElement;
      const scrollTop =
        insertedCoords.top + scrollContainer.scrollTop - padding;

      scrollContainer.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }, 0);
  };

  const changeInstruction = () => {
    nodeViewProps.updateAttributes({
      isSaved: false,
    });
  };

  const modifyPrompt = (val: string) => {
    nodeViewProps.updateAttributes({
      isSaved: true,
      generatedText: val,
    });
  };

  const deleteNode = () => {
    nodeViewProps.deleteNode();
  };

  return (
    <div className="border border-indigo-100 rounded-lg shadow-lg shadow-indigo-500/20 mx-auto">
      <div className="flex justify-between items-center border-b p-4 pe-2 mx-auto">
        <div className="flex items-center gap-3 w-full">
          <Sparkles className="w-4 h-4 flex-shrink-0 text-indigo-500 dark:text-indigo-400 fill-indigo-500 dark:fill-indigo-400" />
          <div
            className="grid [&>textarea]:resize-none [&>textarea]:[grid-area:1/1/2/2] after:[grid-area:1/1/2/2] after:whitespace-pre-wrap after:invisible after:leading-6 after:content-[attr(data-cloned-val)_'_'] w-full max-h-56 overflow-y-auto"
            data-cloned-val={node.attrs.prompt}
          >
            <textarea
              className="w-full text-indigo-600 dark:text-indigo-300 bg-transparent border-none outline-none focus:ring-0 p-0 overflow-hidden"
              readOnly
              value={node.attrs.prompt}
              rows={calculateRows(node.attrs.prompt)}
              style={{ height: "auto" }}
            />
          </div>
        </div>
        <TooltipProvider delayDuration={300}>
          <Tooltip>
            <TooltipTrigger>
              <Button
                onClick={changeInstruction}
                data-testid="edit-instruction"
                variant="ghost"
                className="text-muted-foreground"
                iconOnly
                leadingIcon="PencilSquareIcon"
              ></Button>
            </TooltipTrigger>
            <TooltipContent side="right">Edit instruction</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className="border-b p-4">
        <div className="max-h-80 overflow-auto">
          <Label title="Generated Text"></Label>
          <BlockEditorBasic
            content={editedContent}
            onChange={handleBlockEditorChange}
          />
        </div>
        <ReferenceList references={node.attrs.references} height="max-h-40" />
      </div>

      <Dialog open={isDialogOpen} onOpenChange={handleDialogClose}>
        <DialogContent
          className="flex flex-col max-w-5xl w-full h-[95vh] gap-2 p-3 border-0"
          // onInteractOutside={(e: { preventDefault: () => void }) => {
          //   e.preventDefault();
          // }}
        >
          <DialogHeader className="flex-shrink-0 py-2 pe-7">
            <div className="flex items-center gap-3.5">
              <Sparkles className="w-4 h-4 flex-shrink-0 text-indigo-500 dark:text-indigo-400 fill-indigo-500 dark:fill-indigo-400" />
              <textarea
                className="text-primary/80 bg-transparent border-none resize-none max-h-32 overflow-y-auto w-full focus:ring-0"
                readOnly
                value={node.attrs.prompt}
                rows={calculateRows(node.attrs.prompt)}
              />
            </div>
          </DialogHeader>
          <div className="-mx-3 border-t border-solid" />
          <div className="flex-grow flex overflow-hidden">
            <div className="flex-grow overflow-auto">
              <BlockEditorBasic
                content={dialogContent}
                onChange={handleDialogEditorChange}
              />
            </div>
            <div className="w-64 flex-shrink-0 overflow-auto border-l pl-4">
              <div className="text-sm font-semibold -translate-y-0.5 mb-2 sticky top-0 bg-background z-10 py-1">
                References
              </div>
              <ReferenceList
                references={node.attrs.references}
                height="h-full"
                view="dialog"
              />
            </div>
          </div>
          <div className="-mx-3 border-t border-solid" />
          <DialogFooter className="flex-shrink-0 justify-between sm:justify-between flex-row">
            <div className="flex items-center gap-2">
              <div className="hidden md:block text-xs text-muted-foreground">
                You can edit before inserting.
              </div>
              {/* TODO: Add a button to minimize the dialog and overwrite the content */}
              {/* <Button
                variant="outline"
                size="xs"
                // onClick={}
                className="text-muted-foreground"
              >
                <Minimize2 className="w-3.5 h-3.5" />
              </Button> */}
            </div>
            <div className="flex items-center gap-2">
              <Button variant="ghost" onClick={() => setIsDialogOpen(false)}>
                Cancel
              </Button>
              <Button
                variant="ai"
                onClick={() => {
                  insertAndCloseDialog();
                  insert(dialogContent);
                }}
              >
                Insert
                <CornerDownLeft className="w-4 h-4 ml-1" />
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <div className="flex justify-between items-center p-3">
        <div className="flex items-center gap-2">
          <div className="hidden md:block text-xs text-muted-foreground">
            You can edit before inserting.
          </div>
          <Button
            variant="outline"
            size="xs"
            onClick={handleDialogOpen}
            className="text-muted-foreground"
          >
            <Maximize2 className="w-3.5 h-3.5 mr-2" />
            Full screen
          </Button>
        </div>
        <div className="flex space-x-2">
          <Button
            variant="destructiveGhost"
            onClick={deleteNode}
            data-testid="delete"
            className="text-primary hover:text-destructive"
          >
            <TrashIcon className="w-4 h-4 mr-2" />
            Discard
          </Button>
          <Button
            variant="ai"
            onClick={() => insert(editedContent)}
            data-testid="insert"
          >
            Insert
            <CornerDownLeft className="w-4 h-4 ml-1" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Suggestion;
