import React, { useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Loader, Plus } from "lucide-react";
import SelectedSourcesList from "./SelectedAssetList";
import { LibraryRecord } from "@/api/types/library";
import CollectionDetails from "./CollectionDetails";
import { useGetTagsQuery } from "@/api/api";

interface CreateCollectionPanelProps {
  collectionId?: string;
  collectionName: string;
  setCollectionName: (name: string) => void;
  collectionColor: string;
  setCollectionColor: (color: string) => void;
  saveAsCollection: boolean;
  setSaveAsCollection: (save: boolean) => void;
  selectedAssets: LibraryRecord[];
  handleRemoveAsset: (assetId: string) => void;
  handleTuner: (asset: LibraryRecord) => void;
  handleSubmit: () => void;
  isSubmitting: boolean;
}

const CreateCollectionPanel: React.FC<CreateCollectionPanelProps> = ({
  collectionId,
  collectionName,
  setCollectionName,
  collectionColor,
  setCollectionColor,
  setSaveAsCollection,
  selectedAssets,
  handleRemoveAsset,
  handleTuner,
  handleSubmit,
  isSubmitting,
}) => {
  const isDisabled = selectedAssets.length === 0 || collectionName === "";
  const isEditing = !!collectionId;
  const { data: tagsData } = useGetTagsQuery(
    {
      type: "collection",
      limit: 1000,
      offset: 0,
    },
    { skip: !collectionId }
  );
  const collectionData = tagsData?.results.find(
    (tag) => tag.id === collectionId
  );
  useEffect(() => {
    if (!collectionId) {
      setSaveAsCollection(true);
    }
  }, []);

  useEffect(() => {
    if (collectionData) {
      setCollectionName(collectionData.name);
      setCollectionColor(collectionData.hex_color || "");
    }
  }, [collectionData]);

  return (
    <div className="p-4 w-1/2 flex flex-col">
      <h3 className="text-lg font-medium pb-2">
        {!!collectionId ? "Edit" : "Create"} collection
      </h3>
      <div className="flex items-center space-x-2 pb-2 font-light text-muted-foreground text-sm">
        Collections are groups of sources that help you set the context of a
        Page quickly and consistently.
      </div>
      <div className="pb-2">
        <CollectionDetails
          isEditingCollection={!!collectionId}
          collectionId={collectionId}
          collectionName={collectionName}
          setCollectionName={setCollectionName}
          collectionColor={collectionColor}
          setCollectionColor={setCollectionColor}
        />
      </div>
      <SelectedSourcesList
        selectedAssets={selectedAssets}
        handleRemoveAsset={handleRemoveAsset}
        handleTuner={handleTuner}
        maxHeight="244px"
      />
      <Button
        disabled={isDisabled}
        onClick={handleSubmit}
        className="w-full px-4 py-2 mt-4 self-end"
      >
        <>
          <Plus className="w-4 h-4 mr-2" />
          {!isEditing && (
            <>{isSubmitting ? "Creating..." : "Create collection"}</>
          )}
          {isEditing && (
            <>{isSubmitting ? "Updating..." : "Update collection"}</>
          )}
          {isSubmitting && <Loader className="w-4 h-4 ml-2 animate-spin " />}
        </>
      </Button>
    </div>
  );
};

export default CreateCollectionPanel;
