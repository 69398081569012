import { NodeViewProps } from "@tiptap/react";
import { useEffect, useMemo } from "react";
import { Button } from "@/components/ui/button";
import {
  Loader,
  Play,
  StopCircleIcon,
  CircleAlert,
  Sparkles,
} from "lucide-react";
import {
  INSTRUCTIONS_CUSTOM_EVENT_REMOVE,
  INSTRUCTIONS_CUSTOM_EVENT_RUN,
} from "../RunAllInstructions";
import {
  AddSuggestionEvent,
  INSTRUCTIONS_ADD_SUGGESTION,
  INSTRUCTIONS_CUSTOM_EVENT_REMOVE_POLL,
} from "../useInstructionPoller";
import { Alert, AlertDescription } from "@/components/ui/alert";
import useGetCallId from "./useGetCallId";
import DeleteAction from "./DeleteAction";
import { useDriverTemplate } from "@/hooks/useDriverTemplate";
import useGetSources from "@/hooks/useGetSources";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";

type Props = {
  nodeViewProps: NodeViewProps;
};

interface Reference {
  content: string;
  score: number;
  metadata: {
    source_content_id: string;
    content_type: string;
    relative_path: string;
    workspace_id: string;
    codebase_id: string;
    content_id: string;
    chunk_number: number;
    semantic_score: number;
    text_keyword_score: number;
    path_keyword_score: number;
    result_number: number;
  };
}

export type FileReference = {
  name: string;
  workspace_id: string;
  codebase_id: string;
  relative_path: string;
  content_type: string;
  source_content_id: string;
};

const InstructionSaved = (props: Props) => {
  const { nodeViewProps } = props;
  const { node } = nodeViewProps;
  const isLoading = node.attrs.isLoading;
  const hasError = !!node.attrs.error;
  const { run } = useGetCallId(nodeViewProps);
  const { isPreview, isTemplate } = useDriverTemplate();
  const { hasSources } = useGetSources();
  const disabled = isTemplate || !hasSources;

  const transformReferences = (references: Reference[]) => {
    const fileMap = new Map<string, FileReference>();

    references.forEach((result) => {
      const key = result.metadata.relative_path;
      if (!key) {
        return;
      }
      if (!fileMap.has(key)) {
        fileMap.set(key, {
          name: key.split("/").pop() || "",
          workspace_id: result.metadata.workspace_id,
          codebase_id: result.metadata.codebase_id,
          relative_path: key,
          content_type: result.metadata.content_type,
          source_content_id: result.metadata.source_content_id,
        });
      }
    });

    // Convert the Map structure to an array of FileReferences with sorted chunks
    return Array.from(fileMap.values()).map((file) => ({
      ...file,
    }));
  };

  const editInstruction = () => {
    nodeViewProps.updateAttributes({
      isSaved: false,
      error: "",
    });
    const detail = {
      id: node.attrs.identifier,
    };
    document.dispatchEvent(
      new CustomEvent(INSTRUCTIONS_CUSTOM_EVENT_REMOVE, { detail })
    );
  };

  const cancel = () => {
    nodeViewProps.updateAttributes({
      isLoading: false,
      callId: "",
      error: "",
    });
    const detail = {
      id: node.attrs.callId,
    };
    document.dispatchEvent(
      new CustomEvent(INSTRUCTIONS_CUSTOM_EVENT_REMOVE_POLL, { detail })
    );
  };

  const update = (event: Event) => {
    const e = event as AddSuggestionEvent;
    const detail = e.detail;
    if (node.attrs.callId !== detail.call_id) return;
    if (detail.status === "completed") {
      const transformedReferences = transformReferences(detail.references);
      nodeViewProps.updateAttributes({
        isLoading: false,
        callId: "",
        generatedText: detail.response,
        references: transformedReferences,
        error: detail.error,
        hasLLMContent: true,
      });
    } else if (detail.status === "expired") {
      nodeViewProps.updateAttributes({
        isSaved: true,
        isLoading: false,
        callId: "",
      });
    }
  };

  useEffect(() => {
    document.addEventListener(INSTRUCTIONS_CUSTOM_EVENT_RUN, run);
    document.addEventListener(INSTRUCTIONS_ADD_SUGGESTION, update);
    return () => {
      document.removeEventListener(INSTRUCTIONS_CUSTOM_EVENT_RUN, run);
      document.removeEventListener(INSTRUCTIONS_ADD_SUGGESTION, update);
    };
  }, [node, disabled, update]);

  return (
    <div className="relative rounded-lg border border-border">
      <div
        className="rounded-lg px-4 py-6 group/main"
        data-testid="instruction-saved"
      >
        {!isLoading && (
          <DeleteAction
            nodeViewProps={nodeViewProps}
            node={node}
            className="opacity-0 group-hover/main:opacity-100"
          />
        )}
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center me-1 gap-3 w-full">
            <Sparkles className="w-4 h-4 text-indigo-500 dark:text-indigo-400 fill-indigo-500 dark:fill-indigo-400 flex-shrink-0" />
            <div
              className="grid [&>textarea]:resize-none [&>textarea]:[grid-area:1/1/2/2] after:[grid-area:1/1/2/2] after:whitespace-pre-wrap after:invisible after:leading-6 after:content-[attr(data-cloned-val)_'_'] w-full max-h-56 overflow-y-auto"
              data-cloned-val={node.attrs.prompt}
            >
              <textarea
                className="w-full text-indigo-600 dark:text-indigo-300 bg-transparent border-none outline-none focus:ring-0 p-0 overflow-hidden"
                readOnly
                value={node.attrs.prompt}
                rows={node.attrs.prompt.split("\n").length}
                style={{ height: "auto" }}
              />
            </div>
          </div>
          {!isPreview && (
            <div className="flex items-center space-x-2 py-2.5">
              {isLoading ? (
                <Button
                  onClick={cancel}
                  data-testid="stop-instruction"
                  className="relative group"
                  variant="ghost"
                >
                  <Loader
                    className="animate-spin-slow block group-hover:hidden"
                    size={18}
                    data-testid="loading-indicator"
                  />
                  <StopCircleIcon
                    size={18}
                    className="hidden group-hover:block"
                  />
                </Button>
              ) : (
                <div className="flex items-center gap-1">
                  <Button
                    onClick={editInstruction}
                    title="Edit Instruction"
                    data-testid="edit-instruction"
                    variant="ghost"
                    className="text-muted-foreground"
                    iconOnly
                    leadingIcon="PencilSquareIcon"
                  ></Button>
                  <TooltipProvider delayDuration={0}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          onClick={run}
                          disabled={disabled}
                          variant="ai"
                          data-testid="run-instruction"
                          className="flex items-center py-0"
                        >
                          <Play className="w-3.5 h-3.5 mr-1.5 fill-primary-foreground" />
                          Run
                        </Button>
                      </TooltipTrigger>
                      {disabled && (
                        <TooltipContent>
                          <div className="text-sm">Add a source first</div>
                        </TooltipContent>
                      )}
                    </Tooltip>
                  </TooltipProvider>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {hasError && (
        <div className="p-4 pt-0">
          <Alert variant="destructive" className="flex items-center">
            <CircleAlert className="h-4 w-4 shrink-0 -mt-[1px]" />
            <AlertDescription className="mt-1">
              There was an error running this instruction. Please try again.
            </AlertDescription>
          </Alert>
        </div>
      )}
    </div>
  );
};

export default InstructionSaved;
