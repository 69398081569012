import React, { useState, useEffect } from "react";
import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import mermaid from "mermaid";
import { isMermaidDocument } from "./mermaidUtils";
import { v4 as uuidv4 } from "uuid";

import MermaidBlock from "./MermaidBlock";
import CodeBlock from "./CodeBlock";

mermaid.initialize({
  theme: "dark",
});

const SmartCodeBlock: React.FC<NodeViewProps> = (props) => {
  const { updateAttributes, node } = props;
  const defaultLanguage = node.attrs.language;
  // @ts-ignore
  const text = node.content?.content?.[0]?.textContent?.trim() ?? "";
  const isDiagram = isMermaidDocument(text) || defaultLanguage === "mermaid";
  useEffect(() => {
    if (isDiagram && node?.attrs?.language !== "mermaid") {
      try {
        updateAttributes?.({ language: "mermaid" });
      } catch (error) {
        // when the preview node is inserted into the editor, this method is not available
        // silently fail, this will get called again when editor is ready
      }
    }
  }, [isDiagram, node.attrs.language]);

  useEffect(() => {
    if (node.attrs.identifier) return;
    updateAttributes({ identifier: uuidv4() });
  }, []);

  return (
    <NodeViewWrapper className="code-block group">
      {isDiagram ? (
        <MermaidBlock text={text} nodeViewProps={props} />
      ) : (
        <CodeBlock text={text} nodeViewProps={props} />
      )}
    </NodeViewWrapper>
  );
};

export default SmartCodeBlock;
