import { useToast } from "@/components/ui/use-toast";
import * as React from "react";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Command,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { Loader } from "lucide-react";
const colors = require("tailwindcss/colors");
import { useTranslation } from "next-i18next";

export function tailwindClassToHex(tailwindClass: string) {
  if (tailwindClass.includes("#")) return tailwindClass;
  const [_, colorName, shade] = tailwindClass.split("-");
  const colorObject = colors[colorName];
  return colorObject[shade];
}

interface ChooseTagColorProps<TData, TValue> {
  name: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  setInputValue: (open: string) => void;
  callback: (tag: TagColorOption) => void;
  isLoading: boolean;
}

export interface TagColorOption {
  name: string;
  className: string;
  color: string;
}

const colorOptions: TagColorOption[] = [
  {
    name: "Grey",
    className: "bg-gray-300",
    color: tailwindClassToHex("bg-gray-300"),
  },
  {
    name: "Dark Grey",
    className: "bg-gray-400",
    color: tailwindClassToHex("bg-gray-400"),
  },
  {
    name: "Purple",
    className: "bg-violet-500",
    color: tailwindClassToHex("bg-violet-500"),
  },
  {
    name: "Blue",
    className: "bg-blue-500",
    color: tailwindClassToHex("bg-blue-500"),
  },
  {
    name: "Teal",
    className: "bg-teal-400",
    color: tailwindClassToHex("bg-teal-400"),
  },
  {
    name: "Green",
    className: "bg-green-500",
    color: tailwindClassToHex("bg-green-500"),
  },
  {
    name: "Yellow",
    className: "bg-yellow-400",
    color: tailwindClassToHex("bg-yellow-400"),
  },
  {
    name: "Orange",
    className: "bg-orange-500",
    color: tailwindClassToHex("bg-orange-500"),
  },
  {
    name: "Pink",
    className: "bg-pink-500",
    color: tailwindClassToHex("bg-pink-500"),
  },
  {
    name: "Red",
    className: "bg-red-500",
    color: tailwindClassToHex("bg-red-500"),
  },
];

export function ChooseTagColor<TData, TValue>({
  name,
  open,
  setOpen,
  setInputValue,
  callback,
  isLoading,
}: ChooseTagColorProps<TData, TValue>) {
  const [label, setLabel] = useState<string>("");
  const { toast } = useToast();
  const { t } = useTranslation();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="px-3 pt-0 pb-2 max-w-xs">
        <DialogHeader>
          <div className="hidden">
            <DialogTitle>Choose a color</DialogTitle>
          </div>
          {isLoading && (
            <span className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20 z-10 backdrop-blur-sm">
              <Loader
                className="animate-spin"
                size={32}
                data-testid="loading-indicator"
              />
            </span>
          )}
          <DialogDescription className="min-h-96">
            <Command>
              <CommandInput
                value={label}
                disabled={isLoading}
                onValueChange={setLabel}
                className="w-full border-0 focus:outline-none ring-0 pl-0"
                placeholder="Pick a color for tag"
                classNameWrapper="px-0"
              />
              <CommandList className="pt-2 max-h-full">
                {colorOptions.map((option) => (
                  <CommandItem
                    key={option.className}
                    onSelect={() => callback(option)}
                    className="flex items-center p-2 rounded-lg cursor-pointer hover:bg-gray-100 transition w-full"
                  >
                    <span
                      className={`w-2 h-2 rounded-full mr-3 ${option.className}`}
                    ></span>
                    <span>{option.name}</span>
                  </CommandItem>
                ))}
              </CommandList>
            </Command>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
