import React from "react";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Command, CommandItem, CommandList } from "@/components/ui/command";
import { CodeIcon, ScanEyeIcon } from "lucide-react";

export type CodeViewOption = "code" | "analysis";

interface CodeViewOptionsProps {
  codeViewOptionsOpen: boolean;
  setCodeViewOptionsOpen: (open: boolean) => void;
  handleCodeViewOptionSelect: (option: CodeViewOption) => void;
  view: CodeViewOption;
}

const CodeViewOptions: React.FC<CodeViewOptionsProps> = ({
  codeViewOptionsOpen,
  setCodeViewOptionsOpen,
  handleCodeViewOptionSelect,
  view,
}) => (
  <Popover open={codeViewOptionsOpen} onOpenChange={setCodeViewOptionsOpen}>
    <PopoverTrigger>
      <Button
        variant="ghost"
        className="text-white"
        onMouseDown={(e) => {
          // prosemirror will try to scroll to the text selection if its offscreen
          // instead of engaging this button so we prevent default behavior
          e.preventDefault();
        }}
        onClick={(e) => {
          e.preventDefault();
          setCodeViewOptionsOpen(true);
        }}
      >
        {view.charAt(0).toUpperCase() + view.slice(1)}
      </Button>
    </PopoverTrigger>
    <PopoverContent className="w-[180px] p-0" asChild>
      <Command>
        <CommandList>
          <CommandItem
            value="code"
            onSelect={() => handleCodeViewOptionSelect("code")}
          >
            <CodeIcon className="w-4 h-4 mr-2" />
            Code
          </CommandItem>
          <CommandItem
            value="analysis"
            onSelect={() => handleCodeViewOptionSelect("analysis")}
          >
            <ScanEyeIcon className="w-4 h-4 mr-2" />
            Analysis
          </CommandItem>
        </CommandList>
      </Command>
    </PopoverContent>
  </Popover>
);

export default CodeViewOptions;
