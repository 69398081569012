import React from "react";
import { Library, Shapes, BookOpen, SlidersHorizontal } from "lucide-react";

interface SourceInfoItemProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const SourceInfoItem: React.FC<SourceInfoItemProps> = ({
  icon,
  title,
  description,
}) => (
  <div className="flex items-start">
    <div className="flex-shrink-0">
      <div className="flex items-center justify-center p-1.5 rounded-md bg-background text-primary border border-border">
        {icon}
      </div>
    </div>
    <div className="ml-3">
      <h3 className="text-sm font-medium text-foreground">{title}</h3>
      <p className="mt-1 text-sm text-muted-foreground">{description}</p>
    </div>
  </div>
);

const TuningHighlight: React.FC = () => (
  <div className="mt-6 p-4 bg-muted rounded-lg border border-border">
    <div className="flex items-center mb-2">
      <SlidersHorizontal className="h-4 w-4 text-primary mr-2" />
      <h3 className="text-sm font-semibold text-foreground">Tuning</h3>
    </div>
    <p className="text-sm text-muted-foreground leading-normal">
      Improve generated content when searching within a codebase by selecting
      which files and folders can be referenced. Look for the
      <SlidersHorizontal className="inline h-3 w-3 text-primary mx-1" />
      icon beside a codebase to access tuning options.
    </p>
  </div>
);

const SourceInfoPopoverContent: React.FC = () => {
  return (
    <div className="p-6">
      <h2 className="text-xl font-bold text-foreground mb-2">Sources</h2>
      <p className="text-sm text-muted-foreground mb-4">
        Sources are key to creating pages with smart instructions. They provide
        the background information for AI-generated content:
      </p>
      <div className="space-y-4">
        <SourceInfoItem
          icon={<Library className="h-4 w-4" />}
          title="Context for Smart Instructions"
          description="Sources supply the information that smart instructions use to generate relevant content."
        />
        <SourceInfoItem
          icon={<Shapes className="h-4 w-4" />}
          title="Multiple File Types"
          description="You can use various file formats as sources, including code, PDFs, and documents."
        />
        <SourceInfoItem
          icon={<BookOpen className="h-4 w-4" />}
          title="Improved Accuracy"
          description="By referencing specific sources, you help ensure that generated content is more accurate and relevant."
        />
      </div>
      <TuningHighlight />
    </div>
  );
};

export default SourceInfoPopoverContent;
