import React, { useCallback, useEffect, useRef, useState } from "react";

import { Command, MenuListProps } from "./types";
import { Surface } from "@/components/BlockEditor/ui/Surface";
import { DropdownButton } from "@/components/BlockEditor/ui/Dropdown";
import { Icon } from "@/components/BlockEditor/ui/Icon";

export const MenuList = React.forwardRef((props: MenuListProps, ref) => {
  const scrollContainer = useRef<HTMLDivElement>(null);
  const activeItem = useRef<HTMLButtonElement>(null);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const [selectedCommandIndex, setSelectedCommandIndex] = useState(0);
  const [interactionSource, setInteractionSource] = useState<
    "keyboard" | "mouse"
  >("keyboard");

  // Anytime the groups change, i.e. the user types to narrow it down, reset the current selection to the first menu item

  let mouseHasMoved = false;

  const setMouseMoved = () => {
    mouseHasMoved = true;
    window.removeEventListener("mousemove", setMouseMoved);
  };

  useEffect(() => {
    if (props.items.length > 0) {
      setInteractionSource("keyboard");
      setSelectedGroupIndex(0);
      setSelectedCommandIndex(0);
      mouseHasMoved = false;
      window.addEventListener("mousemove", setMouseMoved);
    }

    return () => {
      window.removeEventListener("mousemove", setMouseMoved);
    };
  }, [props.items]);

  // Disable window scroll when menu is active
  useEffect(() => {
    setSelectedGroupIndex(0);
    setSelectedCommandIndex(0);
  }, [props.items]);

  useEffect(() => {
    const disableScroll = (event: WheelEvent | TouchEvent) => {
      if (
        props.items.length > 0 &&
        !scrollContainer.current?.contains(event.target as Node)
      ) {
        event.preventDefault();
      }
    };

    const handleScroll = (event: WheelEvent) => {
      if (scrollContainer.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          scrollContainer.current;
        const isAtTop = scrollTop === 0;
        const isAtBottom = scrollTop + clientHeight === scrollHeight;

        if ((isAtTop && event.deltaY < 0) || (isAtBottom && event.deltaY > 0)) {
          event.preventDefault();
        }
      }
    };

    if (props.items.length > 0) {
      window.addEventListener("wheel", disableScroll, { passive: false });
      window.addEventListener("touchmove", disableScroll, { passive: false });
      scrollContainer.current?.addEventListener("wheel", handleScroll, {
        passive: false,
      });
    }

    return () => {
      window.removeEventListener("wheel", disableScroll);
      window.removeEventListener("touchmove", disableScroll);
      scrollContainer.current?.removeEventListener("wheel", handleScroll);
    };
  }, [props.items.length]);

  const selectItem = useCallback(
    (groupIndex: number, commandIndex: number) => {
      const command = props.items[groupIndex].commands[commandIndex];
      props.command(command);
    },
    [props]
  );

  React.useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
      setInteractionSource("keyboard"); // Set interaction source to keyboard on key press

      if (event.key === "ArrowDown") {
        if (!props.items.length) {
          return false;
        }

        const commands = props.items[selectedGroupIndex].commands;

        let newCommandIndex = selectedCommandIndex + 1;
        let newGroupIndex = selectedGroupIndex;

        if (commands.length - 1 < newCommandIndex) {
          newCommandIndex = 0;
          newGroupIndex = selectedGroupIndex + 1;
        }

        if (props.items.length - 1 < newGroupIndex) {
          newGroupIndex = 0;
        }

        setSelectedCommandIndex(newCommandIndex);
        setSelectedGroupIndex(newGroupIndex);

        return true;
      }

      if (event.key === "ArrowUp") {
        if (!props.items.length) {
          return false;
        }

        let newCommandIndex = selectedCommandIndex - 1;
        let newGroupIndex = selectedGroupIndex;

        if (newCommandIndex < 0) {
          newGroupIndex = selectedGroupIndex - 1;
          newCommandIndex =
            props.items[newGroupIndex]?.commands.length - 1 || 0;
        }

        if (newGroupIndex < 0) {
          newGroupIndex = props.items.length - 1;
          newCommandIndex = props.items[newGroupIndex].commands.length - 1;
        }

        setSelectedCommandIndex(newCommandIndex);
        setSelectedGroupIndex(newGroupIndex);

        return true;
      }

      if (event.key === "Enter") {
        if (
          !props.items.length ||
          selectedGroupIndex === -1 ||
          selectedCommandIndex === -1
        ) {
          return false;
        }

        selectItem(selectedGroupIndex, selectedCommandIndex);

        return true;
      }

      return false;
    },
  }));

  useEffect(() => {
    if (activeItem.current && scrollContainer.current) {
      const offsetTop = activeItem.current.offsetTop;
      const offsetHeight = activeItem.current.offsetHeight;

      scrollContainer.current.scrollTop = offsetTop - offsetHeight;
    }
  }, [selectedCommandIndex, selectedGroupIndex]);

  const createCommandClickHandler = useCallback(
    (groupIndex: number, commandIndex: number) => {
      return () => {
        selectItem(groupIndex, commandIndex);
      };
    },
    [selectItem]
  );

  const createCommandMouseEnterHandler = useCallback(
    (groupIndex: number, commandIndex: number) => {
      return () => {
        if (mouseHasMoved) {
          setInteractionSource("mouse");
          setSelectedGroupIndex(groupIndex);
          setSelectedCommandIndex(commandIndex);
        }
      };
    },
    []
  );

  if (!props.items.length) {
    return null;
  }

  return (
    <Surface
      ref={scrollContainer}
      className="text-black max-h-[min(80vh,34rem)] min-w-80 overflow-auto flex-wrap p-1.5"
    >
      <div className="grid grid-cols-1 gap-0.5">
        {props.items.map((group, groupIndex: number) => (
          <React.Fragment key={`${group.title}-wrapper`}>
            {group.commands.map((command: Command, commandIndex: number) => (
              <DropdownButton
                key={`${command.label}`}
                isActive={
                  selectedGroupIndex === groupIndex &&
                  selectedCommandIndex === commandIndex
                }
                onClick={createCommandClickHandler(groupIndex, commandIndex)}
                onMouseEnter={createCommandMouseEnterHandler(
                  groupIndex,
                  commandIndex
                )}
                className={command.name === "aiMenu" ? "py-3" : "py-0.5"}
              >
                <Icon
                  name={command.iconName}
                  strokeWidth={1.75}
                  className="mr-1 text-muted-foreground"
                />
                <div className="flex-col align-top gap-2.5 text-barely-smaller">
                  {command.label}
                  <div className="text-xs text-muted-foreground">
                    {command.subLabel}
                  </div>
                </div>
                <div className="text-muted-foreground text-xs ml-auto">
                  {command.shortcut}
                </div>
              </DropdownButton>
            ))}
            {/* Render <hr> only if it's not the last group */}
            {groupIndex < props.items.length - 1 && (
              <hr className="border-border dark:border-primary/15 my-1" />
            )}
          </React.Fragment>
        ))}
      </div>
    </Surface>
  );
});

MenuList.displayName = "MenuList";

export default MenuList;
