import { NodeViewProps } from "@tiptap/react";
import {
  INSTRUCTIONS_CUSTOM_EVENT_REMOVE,
} from "../RunAllInstructions";
import {
  INSTRUCTIONS_CUSTOM_EVENT_ADD_POLL,
} from "../useInstructionPoller";
import { usePostAgentPipelineAsyncMutation } from "@/api/api";
import useGetSources from "@/hooks/useGetSources";
import { getContextAroundNodePosition } from "@/utils/editorTextSelectionUtils";
import { AgentPipeLineRequest, AgentStepRequest } from "@/api/types/agent";

const defaultAgentSteps: AgentStepRequest[] = [
  { step_type: "prompt_augmentation" },
  { step_type: "smart_instruction" },
  { step_type: "copy_editor" },
];

const useGetCallId = (props: NodeViewProps, useContext: boolean = true, agentSteps: AgentStepRequest[] = defaultAgentSteps) => {
  const { node, editor, getPos } = props;
  const [postAgentPipelineAsync] = usePostAgentPipelineAsyncMutation();
  const { sourcePaths, hasSources } = useGetSources();

  const run = (updatedAttributes: any = {}) => {
    const attrs = { ...node.attrs, ...updatedAttributes };
    if (!hasSources) {
      props.updateAttributes({
        error: "No sources found",
      });
      return;
    }

    const nodePosition = getPos();
    const { contextBefore, contextAfter } = getContextAroundNodePosition(editor, nodePosition);

    props.updateAttributes({
      isLoading: true,
      error: "",
    });
    const payload: AgentPipeLineRequest = {
      prompt: attrs.prompt,
      scope: {
        paths: sourcePaths,
      },
      steps: agentSteps,
    };
    if (useContext) {
      payload.context = {
        selected_text: "",
        before_selected_text: contextBefore,
        after_selected_text: contextAfter,
      };
    }
    postAgentPipelineAsync(payload)
      .then((result) => {
        const callId = result?.data?.call_id;
        if (!callId) throw new Error("No call id returned");
        props.updateAttributes({
          callId,
        });
        const detail = {
          id: callId,
        };
        document.dispatchEvent(
          new CustomEvent(INSTRUCTIONS_CUSTOM_EVENT_REMOVE, {
            detail: {
              id: node.attrs.identifier,
            },
          })
        );
        document.dispatchEvent(
          new CustomEvent(INSTRUCTIONS_CUSTOM_EVENT_ADD_POLL, { detail })
        );
      })
      .catch(() => {
        props.updateAttributes({
          error: "Failed to submit Smart Instruction",
          isLoading: false,
        });
      });
  };

  return {
    run,
  };
};

export default useGetCallId;
