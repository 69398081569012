import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Loader, Minus, Plus, InfoIcon } from "lucide-react";
import SaveAsCollection from "./SaveAsCollection";
import SelectedSourcesList from "./SelectedAssetList";
import { LibraryRecord } from "@/api/types/library";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import SourceInfoPopoverContent from "../Notes/NoteSidebar/Sources/SourceInfoPopoverContent";

interface AssetManagementPanelProps {
  collectionName: string;
  setCollectionName: (name: string) => void;
  collectionColor: string;
  setCollectionColor: (color: string) => void;
  saveAsCollection: boolean;
  setSaveAsCollection: (save: boolean) => void;
  selectedAssets: LibraryRecord[];
  handleRemoveAsset: (assetId: string) => void;
  handleTuner: (asset: LibraryRecord) => void;
  addAssetsDisabled: boolean;
  onSubmit?: (LibraryRecord: LibraryRecord[]) => void;
  handleSubmit: () => void;
  shouldRemoveAll: boolean;
  isSubmitting: boolean;
}

const AssetManagementPanel: React.FC<AssetManagementPanelProps> = ({
  collectionName,
  setCollectionName,
  collectionColor,
  setCollectionColor,
  saveAsCollection,
  setSaveAsCollection,
  selectedAssets,
  handleRemoveAsset,
  handleTuner,
  addAssetsDisabled,
  onSubmit,
  handleSubmit,
  shouldRemoveAll,
  isSubmitting,
}) => {
  const [infoOpen, setInfoOpen] = useState(false);

  return (
    <div className="p-4 w-1/2 flex flex-col">
      <div className="flex items-center">
        <h3 className="text-lg font-medium">Sources</h3>
        <Popover open={infoOpen} onOpenChange={setInfoOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="ghost"
              size="xs"
              className="ml-0.5 px-1.5 text-muted-foreground hover:text-foreground focus:outline-none"
            >
              <InfoIcon className="h-3.5 w-3.5" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[640px] p-0">
            <SourceInfoPopoverContent />
          </PopoverContent>
        </Popover>
      </div>
      <div className="flex items-center space-x-2 pb-6 font-light text-muted-foreground text-sm">
        Sources are used as context for content generation.{" "}
        <Button
          variant="link"
          className="px-0.5 font-light text-muted-foreground underline hover:bg-muted hover:text-primary"
          onClick={() => setInfoOpen(true)}
        >
          Learn more
        </Button>
      </div>
      <SaveAsCollection
        collectionName={collectionName}
        setCollectionName={setCollectionName}
        collectionColor={collectionColor}
        setCollectionColor={setCollectionColor}
        saveAsCollection={saveAsCollection}
        setSaveAsCollection={setSaveAsCollection}
      />
      <SelectedSourcesList
        selectedAssets={selectedAssets}
        handleRemoveAsset={handleRemoveAsset}
        handleTuner={handleTuner}
      />
      <Button
        onClick={() => {
          if (onSubmit) {
            onSubmit(selectedAssets);
          }
          handleSubmit();
        }}
        className="w-full px-4 py-2 mt-4 self-end"
      >
        {shouldRemoveAll ? (
          <>
            <Minus className="w-4 h-4 mr-2" />
            {isSubmitting ? "Removing..." : "Remove Assets"}
            {isSubmitting && <Loader className="w-4 h-4 ml-2 animate-spin " />}
          </>
        ) : (
          <>
            <Plus className="w-4 h-4 mr-2" />
            {isSubmitting ? "Adding..." : "Add sources"}
            {isSubmitting && <Loader className="w-4 h-4 ml-2 animate-spin " />}
          </>
        )}
      </Button>
    </div>
  );
};

export default AssetManagementPanel;
