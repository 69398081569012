import { LibraryRecord } from "@/api/types/library";
import { useOrg } from "@/contexts/OrgContext";


export const getSourceLink = (organizationName: string | null, source: LibraryRecord) => {
    if (source.content_type_name === "supplemental-document") {
        return `/${organizationName}/pdf/${source.id}`;
    } else if (source.content_type_name === "codebase") {
        return `/${organizationName}/ws/${source.workspace_id}/cb/${source.codebase_id}/tree/${source.content_name}`;
    } else if (source.content_type_name === "application_note") {
        return `/${organizationName}/page/${source.id}`;
    }
    return `/${organizationName}/library/${source.id}`;
}


export const useGetSourceLink = (source: LibraryRecord) => {
    const { organizationName } = useOrg();
    return getSourceLink(organizationName, source);
};
