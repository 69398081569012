import React, { useState } from "react";
import { CheckIcon, DocumentDuplicateIcon } from "@heroicons/react/20/solid";

interface CopyCodeButtonProps {
  text: string;
}

const CopyCodeButton: React.FC<CopyCodeButtonProps> = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyCode = () => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <button
      onClick={handleCopyCode}
      className="p-1.5 rounded text-neutral-300 hover:bg-neutral-100/15 active:bg-neutral-100/10"
    >
      {copied ? (
        <CheckIcon className="h-5 w-5 text-green-400" />
      ) : (
        <DocumentDuplicateIcon className="h-5 w-5" />
      )}
    </button>
  );
};

export default CopyCodeButton;
