import { Editor } from "@tiptap/react";

export const getSelectedText = (editor: Editor | null): string => {
  if (editor && editor.state.selection) {
    const { from, to } = editor.state.selection;
    return editor.state.doc.textBetween(from, to, " ");
  }
  return "";
};

export const getContextAroundSelection = (
  editor: Editor | null
): { contextBefore: string; contextAfter: string } => {
  if (!editor) return { contextBefore: "", contextAfter: "" };

  const { from, to } = editor.state.selection;
  const contextBefore = editor.state.doc.textBetween(0, from, " ");
  const contextAfter = editor.state.doc.textBetween(
    to,
    editor.state.doc.content.size,
    " "
  );

  return { contextBefore, contextAfter };
};

export const getContextAroundNodePosition = (
  editor: Editor,
  nodePosition: number
): { contextBefore: string; contextAfter: string } => {
  if (!editor) return { contextBefore: "", contextAfter: "" };
  const { doc } = editor.state;
  const contextBefore = doc.textBetween(0, nodePosition, " ");
  const contextAfter = doc.textBetween(nodePosition, doc.content.size, " ");
  return { contextBefore, contextAfter };
};
