"use client";

import React, { useRef, useEffect } from "react";

import { useBlockEditorBasic } from "./hooks/useBlockEditorBasic";
import { EditorContent } from "@tiptap/react";
import { transformLibraryContent } from "@/utils/formatEditorContentUtil";

import {
  TableColumnMenu,
  TableRowMenu,
} from "@/components/BlockEditor/extensions/Table/menus";
import ErrorBoundary from "@/components/ErrorBoundary";

interface ContentEditorProps {
  content?: string;
  onChange?: (content: string) => void;
  editable?: boolean;
}

export const BlockEditorBasic = ({
  content,
  onChange,
  editable = true,
}: ContentEditorProps) => {
  const recordContent = transformLibraryContent({ content, content_name: "" });

  const { editor } = useBlockEditorBasic({
    editorContent: recordContent.content,
    editable: editable,
  });

  const menuContainerRef = useRef(null);

  useEffect(() => {
    if (editor && onChange) {
      const handleUpdate = () => {
        onChange(editor.getHTML());
      };
      editor.on("update", handleUpdate);
      return () => {
        editor.off("update", handleUpdate);
      };
    }
  }, [editor, onChange]);

  return (
    <div id="parent" ref={menuContainerRef}>
      <ErrorBoundary>
        <EditorContent editor={editor} />
      </ErrorBoundary>
      {editor && (
        <>
          <TableRowMenu editor={editor} appendTo={menuContainerRef} />
          <TableColumnMenu editor={editor} appendTo={menuContainerRef} />
        </>
      )}
    </div>
  );
};

export default BlockEditorBasic;
