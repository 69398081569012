import { useState, useEffect } from "react";
import {
    useAddContentToCollectionMutation,
    useAddSourceToDocumentMutation,
    useCreateTagMutation,
    useGetDocumentSourcesQuery,
    useRemoveContentFromCollectionMutation,
    useUpdateTagMutation,
} from "@/api/api";
import { LibraryRecord } from "@/api/types/library";
import { ContentSource } from "@/api/types/collection";
import { useParams } from "next/navigation";
import { useToast } from "@/components/ui/use-toast";
import { useDriverTemplate } from "@/hooks/useDriverTemplate";

const fakeId = "-fake-id"

export const collapseFilesIntoCodebases = (
    sources: LibraryRecord[]
): LibraryRecord[] => {
    const orphanedSources = sources.filter(source => source.content_type_name.includes("codebase-"));
    const notOrphanedSources = sources.filter(source => !source.content_type_name.includes("codebase-"));

    // convert orphaned sources into codebases for display in the list
    const reducedSources = orphanedSources.reduce<Record<string, LibraryRecord>>(
        (acc, source) => {
            const { codebase_id, codebase_name, ...rest } = source;

            if (!acc[codebase_id]) {
                acc[codebase_id] = {
                    ...rest,
                    codebase_id,
                    codebase_name,
                    content_name: codebase_name,
                    content_type_name: "codebase",
                    children: [source.id],
                    id: source.id + fakeId,
                };
                return acc;
            }

            acc?.[codebase_id]?.children?.push(rest.id);
            return acc;
        },
        {}
    );
    const results = [...notOrphanedSources, ...Object.values(reducedSources)];
    return results;
};

export const defaultScopeContentTypes = [
    "collections",
    "codebase",
    "supplemental-document",
];

export type ContentType = "collection" | "codebase" | "supplemental-document";

export const contentTypeLabels: Record<ContentType, any> = {
    collection: {
        plural: "Collections",
        singular: "Collection",
    },
    codebase: {
        plural: "Codebases",
        singular: "Codebase",
    },
    "supplemental-document": {
        plural: "PDFs",
        singular: "PDF",
    },
};

export const getLabelForContentType = (
    contentType: ContentType,
    plural: boolean = false
) => {
    if (plural) {
        return contentTypeLabels[contentType]?.plural ?? contentType;
    }
    return contentTypeLabels[contentType]?.singular ?? contentType;
};

export const combineAssets = (selectedAssets: LibraryRecord[]): ContentSource[] => {
    return selectedAssets.flatMap((asset) => {
        const isContainerCodebaseWithChildren = asset.id.includes(fakeId)
        if (asset.children && asset.children.length > 0) {
            return asset.children.map((childId) => ({
                source_content_id: childId,
                include: true,
            }));
        }
        if (isContainerCodebaseWithChildren) return [];
        return [{ source_content_id: asset.id, include: true }];
    });
};

interface UseUserDefinedScopeDataParams {
    sources?: LibraryRecord[] | undefined;
    setOpen: (open: boolean) => void;
    isSmartInstruction?: boolean;
    collectionColor: string;
    sourceIdentifier?: string;
    editCollection?: boolean;
    collectionId?: string;
}

export const useUserDefinedScopeData = ({
    sources,
    setOpen,
    isSmartInstruction,
    collectionColor,
    sourceIdentifier,
    editCollection,
    collectionId,
}: UseUserDefinedScopeDataParams) => {
    const urlParams = useParams();
    const sourceId = sourceIdentifier ?? urlParams?.path?.[0]
    const { toast } = useToast();
    const [saveAsCollection, setSaveAsCollection] = useState(false);
    const [collectionName, setCollectionName] = useState("");
    const { isDriverTemplate } = useDriverTemplate();
    const [createCollection, createCollectionProps] = useCreateTagMutation();
    const { isLoading: isCreatingCollection, error: createCollectionError } = createCollectionProps;

    const [updateCollection, updateCollectionProps] = useUpdateTagMutation();
    const { isLoading: isUpdatingCollection, error: updateCollectionError } = updateCollectionProps;

    const [addContentToCollection, { isLoading: isAddingContentToCollection }] = useAddContentToCollectionMutation();
    const [removeContentFromCollection, { isLoading: isRemovingContentFromCollection }] = useRemoveContentFromCollectionMutation();
    const [addSourceToDocument, addSourceToDocumentProps] = useAddSourceToDocumentMutation();
    const { isLoading: isAddingSourceToDocument, error: addSourceToDocumentError } = addSourceToDocumentProps;

    const getDocumentSourcesProps = useGetDocumentSourcesQuery({ documentId: sourceId }, { skip: !sourceId || isDriverTemplate });
    const { data: documentSourcesData, isLoading: isLoadingDocumentSources } = getDocumentSourcesProps;

    const [selectedAssets, setSelectedAssets] = useState<LibraryRecord[]>([]);

    const isSubmitting = isCreatingCollection || isAddingSourceToDocument || isAddingContentToCollection;

    const addAssetToCodebaseAsChild = (prev: LibraryRecord[], asset: LibraryRecord, existingAsset: LibraryRecord) => {
        return prev.map((a) => {
            if (a.codebase_id === asset.codebase_id) {
                const children = Array.from(new Set([...(a.children ?? []), asset.id]));
                return { ...a, children };
            }
            return a;
        });
    }

    const handleAddAsset = (asset: LibraryRecord) => {
        setSelectedAssets((prev) => {
            const existingAsset = prev.find((a) => a.codebase_id === asset.codebase_id);
            const isCodebaseSubtype = asset.content_type_name.includes("codebase-");
            const isChildAsset = existingAsset && isCodebaseSubtype;
            const isSame = prev.find((a) => a.id === asset.id)
            if (isSame) return prev
            if (isChildAsset) {
                return addAssetToCodebaseAsChild(prev, asset, existingAsset)
            } else if (isCodebaseSubtype && !existingAsset) {
                // make a fake codebase to represent child items that are added without a parents
                const fakeCodebase = {
                    ...asset,
                    id: asset.id + fakeId,
                    children: [asset.id],
                    content_name: asset.codebase_name,
                    content_type_name: "codebase",
                }
                return [...prev, fakeCodebase];
            }
            return [...prev, asset];
        });
    };

    const handleRemoveAsset = (assetId: string) => {
        setSelectedAssets((prev) => prev.filter((asset) => asset.id !== assetId));
    };

    const addSourcesToDocument = async (sources: ContentSource[]) => {
        if (isSmartInstruction) return
        if (!sourceId) return
        return await addSourceToDocument({
            sources,
            contentId: sourceId,
        });
    };

    const createCollectionAndAddSources = async () => {
        const { data: collectionData } = await createCollection({
            name: collectionName,
            hex_color: collectionColor,
            type: "collection",
        });
        const collectionId = collectionData?.id;
        if (!collectionId) return;
        const combinedAssets = combineAssets(selectedAssets);
        const collectionPromises = combinedAssets.map((asset) => {
            return addContentToCollection({ collectionId, contentId: asset.source_content_id });
        });
        await Promise.all(collectionPromises);
        await addSourcesToDocument(combinedAssets);
    };

    const updateCollectionAndAddSources = async () => {
        if (!collectionId) return;
        if (!sources) return;
        const updateCollectionParams: UpdateTagParams = {
            tagId: collectionId,
            tag: {
                name: collectionName,
                hex_color: collectionColor,
            }
        }
        const updatePromise = updateCollection(updateCollectionParams);
        const sourcesToRemove = sources.filter((source) => !selectedAssets.includes(source));
        const sourceIds = sources.map((source) => source.id);
        const combinedAssets = combineAssets(selectedAssets).filter((asset) => !sourceIds?.includes(asset.source_content_id));
        const removeFromCollectionPromises = sourcesToRemove.map((asset) => {
            return removeContentFromCollection({ collectionId, contentId: asset.id });
        });
        const addToCollectionPromises = combinedAssets.map((asset) => {
            return addContentToCollection({ collectionId, contentId: asset.source_content_id });
        });
        const allPromises = [updatePromise, ...removeFromCollectionPromises, ...addToCollectionPromises];
        await Promise.all(allPromises);
    };

    const handleSubmit = async () => {
        if (saveAsCollection) {
            await createCollectionAndAddSources();
        } else if (editCollection) {
            await updateCollectionAndAddSources();
        } else {
            const sources = combineAssets(selectedAssets);
            await addSourcesToDocument(sources);
        }
        setOpen(false);
        setSaveAsCollection(false);
        setCollectionName("");
    };

    const handleError = (error: any, title: string, description: string) => {
        if (!error) return;
        toast({
            title,
            description,
            variant: "destructive",
        });
    };

    useEffect(() => {
        let allSources: LibraryRecord[] = [];
        if (!documentSourcesData && sources && sources.length > 0) {
            allSources = [...sources];
        } else if (documentSourcesData) {
            allSources = [...documentSourcesData.results, ...(sources ?? [])];
        }
        setSelectedAssets([])
        const collapsedSources = collapseFilesIntoCodebases(allSources);
        collapsedSources.forEach(handleAddAsset);
    }, [sources, documentSourcesData]);

    useEffect(() => {
        handleError(addSourceToDocumentError, "Error", "Failed to add sources to document");
    }, [addSourceToDocumentError]);

    return {
        selectedAssets,
        handleAddAsset,
        handleRemoveAsset,
        handleSubmit,
        saveAsCollection,
        setSaveAsCollection,
        isSubmitting,
        setSelectedAssets,
        collectionName,
        setCollectionName,
        documentSourcesData
    };
};