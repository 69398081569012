import "@/styles/globals.css";
import "@/styles/driver-check-tree.css";
import React, { FC } from "react";
import type { AppProps } from "next/app";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { Analytics } from "@vercel/analytics/react";
import { OrgProvider, useOrg } from "@/contexts/OrgContext";
import { ThemeProvider } from "@/providers/theme-provider";
import { UserbackProvider, useUserback } from "@userback/react";
import { GoogleTagManager } from "@next/third-parties/google";
import { Toaster } from "@/components/ui/toaster";

import { GeistSans } from "geist/font/sans";
import { GeistMono } from "geist/font/mono";

import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import Auth0Apollo from "@/components/Auth0Apollo";
import { Provider } from "react-redux";
import { apiStore } from "@/api/api";
import security from "@/api/security";

const userbackToken = process.env.NEXT_PUBLIC_USERBACK_TOKEN as string;
const apiAudience = process.env.NEXT_PUBLIC_API_AUDIENCE;
const auth0Domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN;
const clientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID;
import { appWithTranslation } from "next-i18next";
import Racers from "@/components/Racers/Racers";
import { initErrorLogging } from "@/api/initErrorLogger";
import SearchOverlay from "@/components/SearchOverlay";

const UserBackIdentifier: FC = () => {
  const { user } = useAuth0();
  const Userback = useUserback();
  const { organizationName } = useOrg();
  if (user && organizationName) {
    Userback.identify(user?.sub ?? "no-sub", {
      name: user.name,
      email: user.email,
      // @TODO: get the plan from the user
      plan: "enterprise",
      account_id: organizationName,
    });
  }
  return <></>;
};

// Make the various auth0 functions available via the security singleton where needed
const ApiSecurityProvider: FC = () => {
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    user,
  } = useAuth0();
  security.setAccessTokenSilently(getAccessTokenSilently);
  security.setLoginWithRedirect(loginWithRedirect);
  security.setUser(user);
  // if the user is authenticated but doesn't have the ai.driver.roles role, redirect to the login page
  // so they can fetch a new token with the role
  const roles = user?.["ai.driver.roles"] || [];
  const missingRoles = roles.length === 0;
  if (!isLoading && isAuthenticated && missingRoles) {
    loginWithRedirect();
  }
  if (!isLoading && !isAuthenticated) {
    loginWithRedirect();
  }
  return <></>;
};

const app = function App({ Component, pageProps }: AppProps) {
  initErrorLogging();
  return (
    <>
      <Racers />
      <div className={`${GeistSans.variable} ${GeistMono.variable} font-sans`}>
        <Auth0Provider
          domain={auth0Domain!}
          clientId={clientId!}
          cacheLocation="localstorage"
          authorizationParams={{
            audience: apiAudience,
            scope: "openid profile email offline_access read:current_user",
            redirect_uri: process.env.NEXT_PUBLIC_AUTH0_BASE_URL,
          }}
        >
          <ApiSecurityProvider />
          <Provider store={apiStore}>
            <Toaster />
            <UserbackProvider token={userbackToken}>
              <OrgProvider>
                <UserBackIdentifier />
                <Auth0Apollo>
                  <ThemeProvider attribute="class" defaultTheme="light">
                    <Component {...pageProps} />
                    <SearchOverlay />
                  </ThemeProvider>
                </Auth0Apollo>
              </OrgProvider>
            </UserbackProvider>
            <Analytics />
            <SpeedInsights />
            {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS && (
              <GoogleTagManager
                gtmId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}
              />
            )}
          </Provider>
        </Auth0Provider>
      </div>
    </>
  );
};
export default appWithTranslation(app);
