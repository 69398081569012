import { useEffect, useReducer, useState } from "react";
import { Button } from "@/components/ui/button";
import { useInstructionPoller } from "./useInstructionPoller";
import { Play } from "lucide-react";
import { useContentId } from "@/hooks/useContentId";
import { useDriverTemplate } from "@/hooks/useDriverTemplate";
import useGetSources from "@/hooks/useGetSources";

export const INSTRUCTIONS_CUSTOM_EVENT_RUN = "RunAllInstructions";
export const INSTRUCTIONS_CUSTOM_EVENT_REMOVE = "RemoveInstruction";
export const INSTRUCTIONS_CUSTOM_EVENT_ADD = "AddInstruction";

interface InstructionEventDetail {
  id: string;
}
export interface CustomEventWithId<T = any>
  extends CustomEvent<T>,
    InstructionEventDetail {}

interface InstructionAction {
  type: string;
  id: string;
}

const init: Set<string> = new Set();

function reducer(state: Set<string>, action: InstructionAction): Set<string> {
  switch (action.type) {
    case "add":
      let newState = new Set(state);
      newState.add(action.id);
      return newState;
    case "remove":
      let newState2 = new Set(state);
      newState2.delete(action.id);
      return newState2;
    default:
      return state;
  }
}

const RunAllInstructions = () => {
  useInstructionPoller();
  const documentId = useContentId();
  const { isDriverTemplate } = useDriverTemplate();
  const { hasSources } = useGetSources();
  // use a reducer here, because useState will not update from within an event callback
  const [instructions, setInstructions] = useReducer(reducer, init);

  const [rightPanelWidth, setRightPanelWidth] = useState(312); // default value
  const padding = 16; // Adjust this value for desired padding

  const updateRightPanelWidth = () => {
    const panelLayout = localStorage.getItem("panelLayout_page");
    if (panelLayout) {
      const { rightPanelWidth, isRightPanelOpen } = JSON.parse(panelLayout);
      setRightPanelWidth(isRightPanelOpen ? rightPanelWidth : 0);
    }
  };

  useEffect(() => {
    updateRightPanelWidth(); // Initial load

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "panelLayout_page") {
        updateRightPanelWidth();
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(updateRightPanelWidth, 10);
    return () => clearInterval(interval);
  }, []);

  const runAll = () => {
    const event = new CustomEvent(INSTRUCTIONS_CUSTOM_EVENT_RUN);
    document.dispatchEvent(event);
    instructions.forEach((id) => {
      setInstructions({ type: "remove", id });
    });
  };

  const add = (event: Event) => {
    const e = event as CustomEventWithId;
    const id = e.detail.id;
    setInstructions({ type: "add", id });
  };

  const remove = (event: Event) => {
    const e = event as CustomEventWithId;
    const id = e.detail.id;
    setInstructions({ type: "remove", id });
  };

  useEffect(() => {
    document.addEventListener(INSTRUCTIONS_CUSTOM_EVENT_ADD, add);
    document.addEventListener(INSTRUCTIONS_CUSTOM_EVENT_REMOVE, remove);
    return () => {
      document.removeEventListener(INSTRUCTIONS_CUSTOM_EVENT_ADD, add);
      document.removeEventListener(INSTRUCTIONS_CUSTOM_EVENT_REMOVE, remove);
    };
  });

  return (
    <>
      {instructions.size > 0 && (
        <div
          className="fixed top-16 lg:top-3 hidden sm:block z-50"
          style={{ right: `${rightPanelWidth + padding}px` }}
        >
          <Button
            variant="ai"
            size="xs"
            onClick={runAll}
            data-testid="run-all-button"
            disabled={!hasSources}
          >
            <Play className="w-2.5 h-2.5 mr-1 fill-white" />
            Run all
          </Button>
        </div>
      )}
    </>
  );
};

export default RunAllInstructions;
