export const DropdownCategoryTitle = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="text-[.65rem] font-semibold mb-1 uppercase text-muted-foreground px-1.5">
      {children}
    </div>
  );
};

export const DropdownButton = ({
  children,
  isActive,
  onClick,
  disabled,
  className,
  onMouseEnter,
}: {
  children: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  onMouseEnter?: () => void;
}) => {
  let buttonClass = `flex items-center gap-2 py-1.5 px-3 text-sm font-base text-primary text-left w-full rounded ${
    !isActive && !disabled ? "" : ""
  } ${isActive && !disabled ? "bg-primary/10 text-primary" : ""} ${
    disabled ? "text-muted-foreground cursor-not-allowed" : ""
  } ${className}`;

  return (
    <button
      className={buttonClass}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </button>
  );
};
