import { ReactNodeViewRenderer, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import Selection from "../extensions/Selection";
import { SlashCommand } from "../extensions/SlashCommand";

import Placeholder from "@tiptap/extension-placeholder";
import Typography from "@tiptap/extension-typography";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import ListKeymap from "@tiptap/extension-list-keymap";
import CharacterCount from '@tiptap/extension-character-count'
import Blockquote from "@tiptap/extension-blockquote";
import createSmartInstructionNode from "../extensions/Instructions/InstructionPromptNode";
import { SmartCodeBlock } from "../extensions/SmartCodeBlock";
// import { Table, TableCell, TableHeader, TableRow } from "../extensions/Table";
import Table from "@tiptap/extension-table";
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import { usePathname } from "next/navigation";

interface BlockEditorProps {
  editorContent: string;
  editable?: boolean;
}

export const useBlockEditorBasic = ({ editorContent, editable = true }: BlockEditorProps) => {
  const path = usePathname();
  const editor = useEditor({
    editable: editable,
    immediatelyRender: false,
    extensions: [
      // support the old smart instruction node
      createSmartInstructionNode("instructionPrompt").configure({
        disabled: path?.includes('/template/'),
      }),
      // support the new smart instruction node nam
      createSmartInstructionNode("smartInstruction").configure({
        disabled: path?.includes('/template/'),
      }),
      Placeholder.configure({
        placeholder: "Write something, or type  /  for commands...",
      }),
      StarterKit.configure({
        codeBlock: false,
        blockquote: false,
      }),
      Selection,
      Underline.configure({
        HTMLAttributes: {
          class: "editor-underline",
        },
      }),
      Link.extend({
        inclusive: false,
      }).configure({
        HTMLAttributes: {
          class: "editor-link",
        },
      }),
      Typography.configure({
        copyright: false,
        registeredTrademark: false,
        trademark: false,
        servicemark: false,
        oneHalf: false,
        oneQuarter: false,
        threeQuarters: false,
        multiplication: false,
      }),
      Blockquote,
      SmartCodeBlock,
      TaskList,
      TaskItem,
      ListKeymap,
      CharacterCount,
      Table.configure({ resizable: true }),
      TableRow,
      TableHeader,
      TableCell,
      SlashCommand.configure({ includeAI: false }),
    ],
    editorProps: {
      attributes: {
        class:
          "prose prose-base prose-zinc max-w-none focus:outline-none prose-inline-code:bg-neutral-600/5 prose-inline-code:py-0.5 prose-inline-code:px-1 prose-inline-code:mx-0.5 prose-inline-code:rounded prose-inline-code:text-neutral-700 prose-inline-code:border prose-inline-code:border-neutral-500/10 prose-inline-code:text-smaller prose-inline-code:font-normal prose-inline-code:before:hidden prose-inline-code:after:hidden prose-ul:mb-8 prose-ol:mb-8 ",
      },
    },
    content: editorContent,
  });

  const characterCount = editor?.storage.characterCount.characters() || 0;
  const wordCount = editor?.storage.characterCount.words() || 0;

  return { editor, characterCount, wordCount };
};