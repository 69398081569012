import React, { useRef, useEffect, useState } from "react";
import { NodeViewContent, NodeViewProps } from "@tiptap/react";
import { renderMermaid } from "./mermaidUtils";
import MermaidViewOptions from "./MermaidViewOptions";
import { MermaidViewOption } from "./MermaidViewOptions";
import CopyCodeButton from "./CopyCode";
import LanguageSelect from "./LanguageSelect";
import FullScreenDiagramDialog from "./FullScreenDiagramDialog";
import { Maximize2Icon } from "lucide-react";

interface MermaidBlockProps {
  text: string;
  nodeViewProps: NodeViewProps;
}

const MermaidBlock: React.FC<MermaidBlockProps> = ({ text, nodeViewProps }) => {
  const [viewOptionsOpen, setViewOptionsOpen] = useState(false);
  const mermaidRefDiagram = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const codeRef = useRef(null);
  const { node, updateAttributes, extension } = nodeViewProps;
  const viewOption = node.attrs.view || "split";
  const showDiagram = viewOption === "split" || viewOption === "preview";
  const showCode = viewOption === "code" || viewOption === "split";
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const handleViewOptionSelect = (option: MermaidViewOption) => {
    updateAttributes({ view: option });
    setViewOptionsOpen(false);
  };

  useEffect(() => {
    if (mermaidRefDiagram.current) {
      renderMermaid(text, mermaidRefDiagram.current);
    }
  }, [text]);

  useEffect(() => {
    if (node.attrs.view === "") {
      updateAttributes({ view: "split" });
    }
  }, []);

  return (
    <>
      <div
        className="relative node-codeBlock"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          className={`items-center absolute top-3 right-3 bg-neutral-900/20 backdrop-blur-sm z-10 ${isHovered ? "flex" : "hidden"}`}
        >
          {showDiagram && (
            <button
              className="p-1.5 rounded text-neutral-300 hover:bg-neutral-100/15 active:bg-neutral-100/10"
              onClick={() => {
                setShowFullScreen(true);
              }}
            >
              <Maximize2Icon className="h-4 w-4" />
            </button>
          )}
          <MermaidViewOptions
            viewOptionsOpen={viewOptionsOpen}
            viewOptions={viewOption}
            setViewOptionsOpen={setViewOptionsOpen}
            handleViewOptionSelect={handleViewOptionSelect}
          />
          <CopyCodeButton text={text} />
          <LanguageSelect
            defaultLanguage={node.attrs.language}
            updateAttributes={updateAttributes}
            extension={extension}
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
          />
        </div>
        <div>
          <pre ref={codeRef}>
            {showCode && <NodeViewContent as="code" spellCheck="false" />}
            <div
              ref={mermaidRefDiagram}
              className="mermaid flex justify-center items-center"
              style={{
                display: showDiagram ? "block" : "none",
              }}
            />
          </pre>
        </div>
      </div>

      <FullScreenDiagramDialog
        showFullScreen={showFullScreen}
        setShowFullScreen={setShowFullScreen}
        diagram={text}
        text={text}
      />
    </>
  );
};

export default MermaidBlock;
