import React, { useEffect, useRef } from "react";
import { NodeViewProps } from "@tiptap/react";

type PromptProps = {
  prompt: string;
  setPrompt: (prompt: string) => void;
  nodeViewProps: NodeViewProps;
  promptRef?: React.RefObject<HTMLTextAreaElement>;
};

const MAX_PROMPT_SIZE = 10000;

const Prompt: React.FC<PromptProps> = ({
  prompt,
  setPrompt,
  nodeViewProps,
  promptRef,
}) => {
  const internalRef = useRef<HTMLTextAreaElement>(null);
  const ref = promptRef || internalRef;

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  }, [prompt]);

  return (
    <div className="relative">
      <textarea
        ref={ref}
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        data-testid="instruction-edit-prompt"
        placeholder="Write an instruction..."
        className="w-full resize-none bg-transparent focus:outline-none focus:border-none outline-none border-none ring-0 focus:ring-0 rounded-lg placeholder:text-muted-foreground text-primary"
        maxLength={MAX_PROMPT_SIZE}
        rows={3} // Initial height
        style={{ minHeight: "6rem", maxHeight: "20rem", overflowY: "auto" }}
      />
      {prompt.length >= MAX_PROMPT_SIZE && (
        <div className="absolute bottom-0 text-red-500 text-xs">
          Max character limit of {MAX_PROMPT_SIZE} reached
        </div>
      )}
    </div>
  );
};

export default Prompt;
