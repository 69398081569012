import { NodeViewProps } from "@tiptap/react";
import { Button } from "@/components/ui/button";
import { INSTRUCTIONS_CUSTOM_EVENT_ADD } from "../RunAllInstructions";
import Prompt from "./Prompt";
import { useEffect, useState, useRef } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Divider } from "@/components/BlockEditor/ui/PopoverMenu";
import { marked } from "marked";
import DeleteAction from "./DeleteAction";
import UserDefinedScope from "@/components/UserDefinedScope/UserDefinedScope";
import { LibraryRecord } from "@/api/types/library";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { InfoIcon } from "lucide-react";
import SmartInstructionInfo from "@/components/Guides/SmartInstructionInfo";

type Props = {
  nodeViewProps: NodeViewProps;
};

const InstructionEdit = (props: Props) => {
  const { nodeViewProps } = props;
  const { node } = nodeViewProps;
  const [prompt, setPrompt] = useState(node.attrs.prompt);
  const rootRef = useRef<HTMLDivElement>(null);
  const promptRef = useRef<HTMLTextAreaElement>(null);
  const [open, setOpen] = useState(false);
  const detail = {
    id: node.attrs.identifier,
  };

  const save = () => {
    nodeViewProps.updateAttributes({
      isSaved: true,
      prompt,
    });
    document.dispatchEvent(
      new CustomEvent(INSTRUCTIONS_CUSTOM_EVENT_ADD, { detail })
    );
  };

  const cancel = () => {
    nodeViewProps.updateAttributes({
      isSaved: true,
    });
  };

  const handleSources = (sources: LibraryRecord[]) => {
    nodeViewProps.updateAttributes({
      sources,
    });
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        event.preventDefault();
        save();
      }
    };

    const rootElement = rootRef.current;
    if (rootElement) {
      rootElement.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (rootElement) {
        rootElement.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [save]);

  useEffect(() => {
    if (promptRef.current) {
      promptRef.current.focus();
      promptRef.current.setSelectionRange(
        promptRef.current.value.length,
        promptRef.current.value.length
      );
    }
  }, []);

  return (
    <div
      ref={rootRef}
      className="border rounded-lg p-3 pb-2 shadow-lg mx-auto space-y-2"
      data-testid="edit-component"
      tabIndex={0}
    >
      {open && (
        <UserDefinedScope
          setOpen={setOpen}
          open={open}
          onSubmit={handleSources}
          sources={node.attrs.sources}
        />
      )}
      <DeleteAction nodeViewProps={nodeViewProps} node={node} />
      <Prompt
        prompt={prompt}
        setPrompt={setPrompt}
        nodeViewProps={nodeViewProps}
        promptRef={promptRef}
      />
      {/* TODO: Design UI and user flow for adding sources */}
      {/* <AddSourceButton
        nodeViewProps={nodeViewProps}
        open={open}
        setOpen={setOpen}
        handleSources={handleSources}
        sources={node.attrs.sources}
      />
      {node.attrs.sources.length > 0 && (
        <Sources nodeViewProps={nodeViewProps} />
      )} */}
      <div className="-mx-3 border-t border-solid" />
      <div className="flex justify-between items-center">
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="ghost" size="sm" className="text-muted-foreground">
              <InfoIcon className="w-4 h-4 mr-2" />
              How to use
            </Button>
          </PopoverTrigger>
          <PopoverContent side="bottom" align="start" className="w-[400px] p-0">
            <SmartInstructionInfo onInsert={setPrompt} />
          </PopoverContent>
        </Popover>
        <div className="flex items-center justify-end">
          <Button
            variant="secondary"
            onClick={cancel}
            className="mr-2"
            data-testid="instruction-cancel"
          >
            Cancel
          </Button>

          <TooltipProvider delayDuration={300}>
            <Tooltip>
              <TooltipTrigger>
                <Button
                  onClick={save}
                  disabled={prompt === ""}
                  variant="ai"
                  data-testid="instruction-save"
                >
                  Save instruction
                </Button>
              </TooltipTrigger>
              <TooltipContent>⌘ + Enter</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      {node.attrs.generatedText !== "" && (
        <div className="p-2 overflow-scroll">
          <h4 className="m-0 mb-1">Generated content:</h4>
          <p
            className="max-h-20"
            dangerouslySetInnerHTML={{
              __html: marked.parse(node.attrs.generatedText).toString(),
            }}
          ></p>
          <Divider />
        </div>
      )}
    </div>
  );
};

export default InstructionEdit;
