import { NodeViewProps } from "@tiptap/react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { File, Folder, SquareArrowOutUpRight, FileText } from "lucide-react";
import { useState, useEffect, useRef, useMemo } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import BlockEditorBasic from "@/components/BlockEditor/BlockEditorBasic";
import { Button } from "@/components/ui/button";
import { useGetLibraryQuery } from "@/api/api";
import { Skeleton } from "@/components/ui/skeleton";
import { useOrg } from "@/contexts/OrgContext";
import Link from "next/link";
import { ScrollArea } from "@/components/ui/scroll-area";
import { FileReference } from "./Saved";
import { validate as isValidUUID } from "uuid";
import { validate } from "graphql";
import SourceCode from "@/components/SourceCode";

interface ReferenceListProps {
  references: FileReference[];
  height?: string;
  view?: "inline" | "dialog" | string; // Allow for future view options
}

const truncatePath = (path: string, maxLength: number) => {
  const segments = path.split("/");
  if (segments.length <= 3) return path;

  const firstSegment = segments.shift();
  const fileName = segments.pop();
  const secondToLast = segments.pop();

  let truncatedPath = firstSegment + "/";
  if (segments.length > 0) {
    truncatedPath += ".../";
  }

  while (
    segments.length > 0 &&
    truncatedPath.length +
      segments[0].length +
      (secondToLast?.length ?? 0) +
      2 <=
      maxLength
  ) {
    truncatedPath += segments.shift() + "/";
  }

  truncatedPath += secondToLast + "/";

  return truncatedPath.length > maxLength
    ? firstSegment + "/.../" + secondToLast + "/"
    : truncatedPath;
};

const truncatePDFName = (relative_path: string) => {
  // Check that the relative path looks like {UUID}_{name}.pdf
  // We should get the `content_name` for the PDF returned with
  // the reference, but the pdf_summary IRs are what are currently
  // returned instead of the supplemental_document with that value
  if (
    relative_path.length > 36 &&
    relative_path[36] === "_" &&
    relative_path.endsWith(".pdf")
  ) {
    if (isValidUUID(relative_path.substring(0, 36)))
      return relative_path.substring(37);
  }
  // If it doesn't look like we expect, fall back to just the relative path w/o modification
  return relative_path;
};

const getPopoverWidthClass = (view: string) => {
  switch (view) {
    case "inline":
      return "w-[18rem] max-w-lg";
    case "dialog":
      return "w-[24rem] max-w-xl";
    default:
      return "w-[18rem] max-w-lg";
  }
};

const getFileInfoLayoutClass = (view: string) => {
  switch (view) {
    case "inline":
      return "flex flex-row items-center";
    case "dialog":
      return "flex flex-col items-start overflow-hidden";
    default:
      return "flex flex-row items-center";
  }
};

const isPDF = (relativePath: string, contentType: string) => {
  const pathParts = relativePath.split("/");
  return (
    (pathParts.length <= 2 && relativePath.toLowerCase().endsWith(".pdf")) ||
    contentType === "pdf_summary"
  );
};

const ReferenceList = ({
  references,
  height = "h-full",
  view = "inline",
}: ReferenceListProps) => {
  const { organizationName } = useOrg();
  const [openPopover, setOpenPopover] = useState<number | null>(null);
  const [expandedContent, setExpandedContent] = useState<number | null>(null);
  const [sideOffset, setSideOffset] = useState(-80);
  const [retryCount, setRetryCount] = useState<Record<number, number>>({});
  const [contentHeights, setContentHeights] = useState<Record<number, number>>(
    {}
  );
  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [hasAdditionalContent, setHasAdditionalContent] = useState<
    Record<number, boolean>
  >({});

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== "undefined") {
        if (window.innerWidth < 640) {
          setSideOffset(-180);
        } else if (window.innerWidth < 1024) {
          setSideOffset(-220);
        } else if (window.innerWidth < 1440) {
          setSideOffset(-200);
        } else if (window.innerWidth < 1560) {
          setSideOffset(-60);
        } else {
          setSideOffset(0);
        }
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const referenceCount = references.length;

  const isFile = (path: string) => {
    const parts = path.split("/");
    return parts[parts.length - 1].includes(".");
  };

  const shouldRenderAsCode = (path: string) => {
    const excludeExtensions = [
      ".pdf",
      ".jpg",
      ".jpeg",
      ".png",
      ".gif",
      ".svg",
      ".mp4",
      ".mp3",
      ".wav",
    ];
    const fileExtension = path.split(".").pop()?.toLowerCase();
    return (
      isFile(path) &&
      fileExtension &&
      !excludeExtensions.includes(`.${fileExtension}`)
    );
  };

  useEffect(() => {
    contentRefs.current = contentRefs.current.slice(0, references.length);

    const updateHeights = () => {
      const newHeights: Record<number, number> = {};
      contentRefs.current.forEach((ref, index) => {
        if (ref) {
          newHeights[index] = ref.scrollHeight;
        }
      });
      setContentHeights((prevHeights) => {
        if (JSON.stringify(prevHeights) !== JSON.stringify(newHeights)) {
          return newHeights;
        }
        return prevHeights;
      });
    };

    updateHeights();
    window.addEventListener("resize", updateHeights);
    return () => window.removeEventListener("resize", updateHeights);
  }, [references]);

  if (referenceCount === 0) {
    return null;
  }
  if (typeof references === "string") {
    return <></>;
  }

  return (
    <Accordion
      type="single"
      collapsible
      className="w-full"
      defaultValue="item-1"
    >
      <AccordionItem value="item-1" style={{ border: "none" }}>
        <AccordionTrigger
          className="mb-0 py-0 text-muted-foreground"
          iconPosition="left"
        >
          {`${referenceCount} references found`}
        </AccordionTrigger>
        <AccordionContent className="pb-0">
          <ScrollArea className={`${height} w-full overflow-y-auto`}>
            <div>
              {references.map((file, index) => (
                <Popover
                  key={index}
                  onOpenChange={(open: boolean) => {
                    setOpenPopover(open ? index : null);
                    if (open) {
                      setRetryCount((prev) => ({ ...prev, [index]: 0 }));
                    }
                  }}
                >
                  <PopoverTrigger asChild>
                    <div
                      className={`flex items-${view === "dialog" ? "start" : "center"} rounded-md py-2 px-2 cursor-pointer hover:bg-muted transition-colors ${
                        openPopover === index ? "bg-muted" : ""
                      }`}
                    >
                      {isPDF(file.relative_path, file.content_type) ? (
                        <FileText
                          className={`mr-2 ${view === "dialog" ? "w-3 h-3 mt-1" : "w-4 h-4"} text-primary flex-shrink-0`}
                        />
                      ) : isFile(file.relative_path) ? (
                        <File
                          className={`mr-2 ${view === "dialog" ? "w-3 h-3 mt-1" : "w-4 h-4"} text-primary flex-shrink-0`}
                        />
                      ) : (
                        <Folder
                          className={`mr-2 ${view === "dialog" ? "w-3 h-3 mt-1" : "w-4 h-4"} text-primary flex-shrink-0`}
                        />
                      )}
                      <div
                        className={`flex flex-row items-center min-w-0 ${view === "dialog" ? "flex-wrap" : ""}`}
                      >
                        <span className="font-medium truncate min-w-0 flex-shrink">
                          {isPDF(file.relative_path, file.content_type)
                            ? truncatePDFName(file.relative_path)
                            : file.name}
                        </span>
                        {!isPDF(file.relative_path, file.content_type) && (
                          <span
                            className={`font-normal text-muted-foreground truncate min-w-0 flex-shrink ${
                              view === "dialog" ? "text-xs w-full mt-1" : "ml-2"
                            }`}
                          >
                            {truncatePath(file.relative_path, 30)}
                          </span>
                        )}
                      </div>
                    </div>
                  </PopoverTrigger>
                  <PopoverContent
                    className={`p-0 overflow-hidden flex flex-col shadow-xl shadow-primary/16 bg-background ${getPopoverWidthClass(view)}`}
                    side="left"
                    sideOffset={16}
                    collisionPadding={8}
                  >
                    <div className="flex flex-col h-full max-h-[70vh]">
                      {/* Header */}
                      <div className="flex flex-col px-4 py-3 border-b">
                        <div className="flex justify-between items-start">
                          <div className="flex-grow overflow-hidden">
                            {!isPDF(file.relative_path, file.content_type) && (
                              <div className="text-xs text-muted-foreground truncate mb-1">
                                {truncatePath(file.relative_path, 50)}
                              </div>
                            )}
                            <div className="text-base font-semibold truncate">
                              {isPDF(file.relative_path, file.content_type)
                                ? truncatePDFName(file.relative_path)
                                : file.name}
                            </div>
                          </div>
                          <Link
                            href={
                              isPDF(file.relative_path, file.content_type)
                                ? `/${organizationName}/pdf/${file.source_content_id}`
                                : `/${organizationName}/ws/${file.workspace_id}/cb/${file.codebase_id}/tree/${file.relative_path}`
                            }
                            passHref
                            target="_blank"
                          >
                            <Button
                              variant="outline"
                              size="sm"
                              className="ml-2 flex-shrink-0 h-6 px-2 text-xs"
                            >
                              Open
                              <SquareArrowOutUpRight className="ml-1 w-3 h-3" />
                            </Button>
                          </Link>
                        </div>
                      </div>
                      {/* Content */}
                      {!isPDF(file.relative_path, file.content_type) && (
                        <div className="flex-grow overflow-y-auto h-[70vh]">
                          {file.content_type === "codebase-file" ? (
                            <SourceCode
                              workspaceId={file.workspace_id}
                              codebaseId={file.codebase_id}
                              path={file.relative_path}
                              nodeKind="file"
                            />
                          ) : (
                            <div className="flex-grow overflow-y-auto">
                              <div
                                className={`items-start overflow-x-hidden ${view === "dialog" ? "mt-3" : ""}`}
                              >
                                <FileContent
                                  file={file}
                                  isOpen={openPopover === index}
                                  onContentCheck={(hasContent) =>
                                    setHasAdditionalContent((prev) => ({
                                      ...prev,
                                      [index]: hasContent,
                                    }))
                                  }
                                  view={view as "inline" | "dialog"}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </PopoverContent>
                </Popover>
              ))}
            </div>
          </ScrollArea>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

const FileContent = ({
  file,
  isOpen,
  onContentCheck,
  view,
}: {
  file: FileReference;
  isOpen: boolean;
  onContentCheck: (hasContent: boolean) => void;
  view: "inline" | "dialog";
}) => {
  const query = useMemo(
    () => ({
      text: file.relative_path,
      limit: 1,
      offset: 0,
      source_content_id:
        file.content_type === "pdf_summary"
          ? [file.source_content_id]
          : undefined,
      order: file.content_type === "pdf_summary" ? 1 : undefined,
      content_type_name:
        file.content_type === "pdf_summary"
          ? ["pdf_summary"]
          : ["short_sentence_description"],
      sort_direction: "DESC",
      sort_by: "updated_at",
      tag_id: [],
    }),
    [file]
  );

  const {
    data: fileContent,
    isLoading: isContentLoading,
    error: contentError,
  } = useGetLibraryQuery(query, {
    skip: !isOpen,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: true,
  });

  useEffect(() => {
    const hasContent = !!fileContent?.results[0]?.content;
    onContentCheck(hasContent);
  }, [fileContent, onContentCheck]);

  if (!isOpen) return null;

  if (isContentLoading) {
    return (
      <div className="px-4 py-4 space-y-2">
        <Skeleton className="h-6 w-3/5 mb-4" />
        <Skeleton className="h-3 w-full" />
        <Skeleton className="h-3 w-4/5" />
        <Skeleton className="h-3 w-3/4" />
        <Skeleton className="h-3 w-5/6" />
      </div>
    );
  }

  if (contentError || !fileContent?.results[0]?.content) return null;

  const content = fileContent.results[0].content;

  return (
    <div className="px-4 py-1 overflow-x-hidden">
      <div className="prose prose-sm max-w-none [&_.ProseMirror]:text-sm [&_.ProseMirror_h1]:text-lg [&_.ProseMirror_h2]:text-base [&_.ProseMirror_h3]:text-sm [&_.ProseMirror_h4]:text-xs [&_.ProseMirror_h5]:text-xs [&_.ProseMirror_h6]:text-xs [&_.ProseMirror_h1]:mt-2 [&_.ProseMirror_h2]:mt-2 [&_.ProseMirror_h3]:mt-2 [&_.ProseMirror_h4]:mt-2 [&_.ProseMirror_h5]:mt-2 [&_.ProseMirror_h6]:mt-2">
        <BlockEditorBasic content={content} editable={false} />
      </div>
    </div>
  );
};

export default ReferenceList;
