import React, { useState, useEffect, useCallback } from "react";
import { useGetSearchMutation } from "../api/api";
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { useBlockEditorBasic } from "./BlockEditor/hooks/useBlockEditorBasic";
import { EditorContent } from "@tiptap/react";
import { transformLibraryContent } from "@/utils/formatEditorContentUtil";
import { debounce } from "lodash";
import SourceCode from "./SourceCode";
import useUser from "@/hooks/useUser";

function getFirstAndLastPathElements(path: string) {
  const pathElements = path.split("/");
  const firstElement = pathElements[0];
  const lastElement = pathElements[pathElements.length - 1];
  return { firstElement, lastElement };
}

const SearchOverlay = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const { isDriverAiUser } = useUser();

  const [getSearch, { data, error, isLoading }] = useGetSearchMutation();
  const [hoveredItem, setHoveredItem] = useState<any>(null);
  const { editor } = useBlockEditorBasic({
    editorContent: "",
    editable: false,
  });
  const contentType = hoveredItem?.metadata.content_type;
  const isCodebaseFile = contentType === "codebase-file";

  const debouncedGetSearch = useCallback(
    debounce((query) => {
      getSearch({
        query,
        algorithm: "hybrid",
        relative_path: [],
      });
    }, 300),
    [getSearch]
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    debouncedGetSearch(event.target.value);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === "k") {
        event.preventDefault();
        setIsVisible((prev) => !prev);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Dialog open={isDriverAiUser && isVisible} onOpenChange={setIsVisible}>
      <DialogTitle className="hidden">Search</DialogTitle>
      <DialogContent className="max-w-[90vw] max-h-[90vh]">
        <div className="flex flex-col">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleInputChange}
            className="search-input mb-4"
          />
          {isLoading && <p className="loading-message">Loading...</p>}
          {error && (
            <p className="error-message">
              An error occurred. Please try again.
            </p>
          )}
          {data && data.results.length > 0 ? (
            <div className="flex gap-4">
              <ul className="flex-1 max-h-[500px] overflow-y-auto">
                {data.results.map((item: any) => {
                  const { firstElement, lastElement } =
                    getFirstAndLastPathElements(item.metadata.relative_path);
                  return (
                    <li
                      key={item.id}
                      className="search-result-item p-2 hover:bg-gray-100 rounded-md transition-colors duration-200"
                      onMouseEnter={() => {
                        setHoveredItem(item);
                        const transformedContent = transformLibraryContent({
                          content: item.content,
                          content_name: "",
                        });
                        editor?.commands.setContent(transformedContent.content);
                      }}
                    >
                      {firstElement} / {lastElement} / (
                      {item.metadata.content_type}) / {item.score}
                    </li>
                  );
                })}
              </ul>
              <div className="flex-1 max-h-[500px] overflow-y-auto tiptap">
                {isCodebaseFile && (
                  <SourceCode
                    workspaceId={hoveredItem?.metadata.workspace_id}
                    codebaseId={hoveredItem?.metadata.codebase_id}
                    path={hoveredItem?.metadata.relative_path}
                    nodeKind="file"
                  />
                )}
                <div className={`${isCodebaseFile ? "hidden" : ""} tiptap`}>
                  <EditorContent editor={editor} />
                </div>
              </div>
            </div>
          ) : (
            !isLoading &&
            !error && <p className="no-results-message">No results found.</p>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SearchOverlay;
