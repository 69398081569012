import React, { useRef, useEffect, MutableRefObject, useState } from "react";
import renderer from "./renderer";
import { DriverTreeNode } from "../DriverTuner";
import { Expand, Minimize2, Search } from "lucide-react";
import { debounce } from "lodash";
import { useTheme } from "next-themes";
import { Button } from "@/components/ui/button";

interface TreeNode {
  id: string;
  state: {
    checked: boolean;
    indeterminate: boolean;
    rootNode?: TreeNode;
  };
  children?: TreeNode[];
}

interface TreeProps {
  data: DriverTreeNode;
  defaultValues?: string[];
  setChecked: (checkedNodes: string[]) => void;
}

const filterOptions = {
  caseSensitive: false,
  exactMatch: false,
  includeAncestors: true,
  includeDescendants: true,
};

const updateCheckboxState = (tree: any) => {
  const checkboxes = tree.contentElement.querySelectorAll(
    'input[type="checkbox"]'
  );
  for (let i = 0; i < checkboxes.length; ++i) {
    const checkbox = checkboxes[i] as HTMLInputElement;
    if (checkbox.hasAttribute("data-indeterminate")) {
      checkbox.indeterminate = true;
    } else {
      checkbox.indeterminate = false;
    }
  }
};

const getCheckedNodes = (tree: TreeNode | null): string[] => {
  if (!tree) return [];
  const checkedNodes: string[] = [];
  const recurse = (tree: TreeNode) => {
    if (tree.state.checked && !tree.state.indeterminate) {
      checkedNodes.push(tree.id);
    } else if (tree.children) {
      tree.children.forEach((child) => recurse(child));
    }
  };
  recurse(tree);
  return checkedNodes;
};

const initializeTree = (
  Tree: any,
  props: TreeProps,
  ref: MutableRefObject<HTMLDivElement | null>,
  treeRef: MutableRefObject<any>,
  theme: string
) => {
  let tree: any;
  try {
    tree = new Tree({
      el: ref.current,
      data: props.data,
      autoOpen: false,
      rowRenderer: renderer(theme),
    });
  } catch (e) {
    // seeing an error here on the infinite-tree package
    // TypeError: el.className.split is not a function
    // we can safely ignore, the tree will get recreated
  }
  if (!tree) return;
  treeRef.current = tree;
  // open the first level of the tree
  tree.nodes.forEach((node: TreeNode) => {
    tree.openNode(node);
  });
  tree.on("contentDidUpdate", () => {
    updateCheckboxState(tree);
  });
  tree.on("clusterDidChange", () => {
    updateCheckboxState(tree);
  });
  if (props.defaultValues) {
    props.setChecked(props.defaultValues);
    tree.nodes.forEach((node: TreeNode) => {
      if (props.defaultValues!.includes(node.id)) {
        tree.checkNode(node);
      }
    });
  } else {
    // check the top node
    props.setChecked([tree.nodes[0].id]);
    tree.checkNode(tree.nodes[0]);
  }
  tree.on("click", (event: MouseEvent) => {
    const currentNode = tree.getNodeFromPoint(event.clientX, event.clientY);
    if (!currentNode) {
      return;
    }

    if ((event.target as HTMLElement).className === "checkbox") {
      event.stopPropagation();
      tree.checkNode(currentNode);
      const rootNode = tree.state.rootNode.children[0];
      const checkedNodes = getCheckedNodes(rootNode);
      props.setChecked(checkedNodes);
      return;
    }
  });
};

const DriverTree: React.FC<TreeProps> = (props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const treeRef = useRef<any>(null);
  const [isToggledAllNodes, setIsToggledAllNodes] = useState(false);
  const [matchCount, setMatchCount] = useState(0);
  const { theme } = useTheme();

  useEffect(() => {
    const loadTree = async () => {
      // @ts-ignore
      return (await import("infinite-tree")).default;
    };
    loadTree().then((Tree) => {
      if (treeRef.current) return;
      initializeTree(Tree, props, ref, treeRef, theme ?? "light");
    });
  }, [props]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value ?? "";
    treeRef.current.filter(searchValue, filterOptions);

    // Remove previous highlights
    const highlightedElements = document.querySelectorAll(".highlight");
    highlightedElements.forEach((el) => {
      el.classList.remove("highlight");
    });

    // Highlight matching text
    if (searchValue) {
      setIsToggledAllNodes(true);
      toggleAllNodes(true);
      const titles = document.querySelectorAll(".infinite-tree-title");
      titles.forEach((node) => {
        const text = node.textContent || "";
        const regex = new RegExp(`(${searchValue})`, "gi");
        const matchCount = (text.match(regex) || []).length;
        setMatchCount((prev) => prev + matchCount);
        const newText = '<span class="highlight">$1</span>';
        node.innerHTML = text.replace(regex, newText);
      });
    } else {
      setMatchCount(0);
    }
  };

  const toggleAllNodes = (shouldOpen: boolean) => {
    setIsToggledAllNodes(shouldOpen);
    const toggleNodesRecursively = (node: any) => {
      if (node.children) {
        node.children.forEach((child: any) => {
          toggleNodesRecursively(child);
        });
      }

      if (shouldOpen) {
        if (!node.state.open) {
          treeRef.current.openNode(node);
        }
      } else {
        if (node.state.depth === 0) {
          treeRef.current.openNode(node);
        } else if (node.state.open) {
          treeRef.current.closeNode(node);
        }
      }
    };

    if (treeRef.current) {
      treeRef.current.nodes.forEach((node: any) => {
        toggleNodesRecursively(node);
      });
    }
  };

  const debouncedHandleSearch = debounce(handleSearch, 300);

  return (
    <>
      <div className="relative w-full mb-2">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground h-4 w-4" />
        <input
          type="text"
          placeholder="Go to folder or file"
          onChange={debouncedHandleSearch}
          className="w-full p-2 pl-10 border border-input rounded-md bg-background"
        />
        {matchCount > 0 && (
          <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-muted-foreground">
            {matchCount === 1 ? `${matchCount} match` : `${matchCount} matches`}
          </span>
        )}
      </div>
      <div className="absolute top-12 right-0 flex space-x-2">
        {isToggledAllNodes && (
          <Button
            variant="ghost"
            size="icon"
            onClick={() => toggleAllNodes(false)}
            className="mb-2 p-2"
          >
            <Minimize2 />
          </Button>
        )}
        {!isToggledAllNodes && (
          <Button
            variant="ghost"
            size="icon"
            onClick={() => toggleAllNodes(true)}
            className="mb-2 p-2"
          >
            <Expand />
          </Button>
        )}
      </div>
      <h4 className="text-xs font-medium text-muted-foreground mb-2">Files</h4>
      <div
        className="driver-check-tree max-h-[260px] overflow-y-scroll"
        ref={ref}
      ></div>
    </>
  );
};

export default DriverTree;
