import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import { useEffect } from "react";
import Edit from "./Edit";
import Saved from "./Saved";
import Suggestion from "./Suggestion";
import EditGenerated from "./EditGenerated";
import {
  INSTRUCTIONS_CUSTOM_EVENT_ADD,
  INSTRUCTIONS_CUSTOM_EVENT_REMOVE,
} from "../RunAllInstructions";
import { INSTRUCTIONS_CUSTOM_EVENT_ADD_POLL } from "../useInstructionPoller";
import Create from "./Create";
import { useDriverTemplate } from "@/hooks/useDriverTemplate";

export const instructionPromptClassName = "instruction-prompt";

const InstructionContainer = (props: NodeViewProps) => {
  const { node } = props;
  const { isDriverTemplate } = useDriverTemplate();

  const addToPollQueue = () => {
    document.dispatchEvent(
      new CustomEvent(INSTRUCTIONS_CUSTOM_EVENT_ADD_POLL, {
        detail: {
          id: node.attrs.callId,
        },
      })
    );
  };

  const addToRunButton = () => {
    document.dispatchEvent(
      new CustomEvent(INSTRUCTIONS_CUSTOM_EVENT_ADD, {
        detail: {
          id: node.attrs.identifier,
        },
      })
    );
  };

  const removeFromInstructions = () => {
    document.dispatchEvent(
      new CustomEvent(INSTRUCTIONS_CUSTOM_EVENT_REMOVE, {
        detail: {
          id: node.attrs.identifier,
        },
      })
    );
  };

  // add the running elements' callId to the poller on page load and node attrs change
  useEffect(() => {
    if (props.editor.isEditable === false) return;
    const hasCallId = node.attrs.callId;
    if (isDriverTemplate) return;
    if (hasCallId) {
      addToPollQueue();
      return;
    }
    if (!node.attrs.isSaved) return;
    if (hasGeneratedText) return;
    addToRunButton();

    return () => {
      removeFromInstructions();
    };
  }, [node, props.editor.isEditable]);

  const hasGeneratedText = node.attrs.hasLLMContent;
  const showCreate = node.attrs.isCreated && !node.attrs.isSaved;
  const showEdit =
    !node.attrs.isCreated && !node.attrs.isSaved && !hasGeneratedText;
  const showEditGenerated = !node.attrs.isSaved && hasGeneratedText;
  const showSaved = node.attrs.isSaved && !hasGeneratedText;
  const showSuggestion = node.attrs.isSaved && hasGeneratedText;

  return (
    <NodeViewWrapper
      className={`${instructionPromptClassName} relative my-4`}
      datatest-id="instruction-prompt-container"
      id={node.attrs.identifier}
    >
      {showCreate && <Create nodeViewProps={props} />}
      {showEdit && <Edit nodeViewProps={props} />}
      {showSaved && <Saved nodeViewProps={props} />}
      {showSuggestion && <Suggestion nodeViewProps={props} />}
      {showEditGenerated && <EditGenerated nodeViewProps={props} />}
    </NodeViewWrapper>
  );
};

export default InstructionContainer;
