import { CodeBlockLowlight } from "@tiptap/extension-code-block-lowlight";
import { ReactNodeViewRenderer } from "@tiptap/react";
import CodeBlockActions from "@/components/BlockEditor/SmartCodeBlock";
import { lowlight } from "lowlight";

export const SmartCodeBlock = CodeBlockLowlight.extend({
    addAttributes() {
        return {
            language: {
                default: "auto",
            },
            view: {
                default: "",
            },
            analysisDiagram: {
                default: "",
            },
            analysisSummary: {
                default: "",
            },
            analysisText: {
                default: "",
            },
            callId: {
                default: "",
            },
            identifier: {
                default: "",
            },
        };
    },
    addNodeView() {
        return ReactNodeViewRenderer(CodeBlockActions);
    },
}).configure({ lowlight }); 