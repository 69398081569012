import React from "react";
import { Button } from "@/components/ui/button";
import { Trash } from "lucide-react";

import { INSTRUCTIONS_CUSTOM_EVENT_REMOVE } from "../RunAllInstructions";

interface DeleteActionProps {
  nodeViewProps: any;
  node: any;
  className?: string;
}

const DeleteAction: React.FC<DeleteActionProps> = ({
  nodeViewProps,
  node,
  className,
}) => {
  const deleteInstruction = () => {
    nodeViewProps.deleteNode();
    const detail = {
      id: node.attrs.identifier,
    };
    document.dispatchEvent(
      new CustomEvent(INSTRUCTIONS_CUSTOM_EVENT_REMOVE, { detail })
    );
  };

  return (
    <Button
      variant="destructiveGhost"
      size="sm"
      onClick={deleteInstruction}
      className={`absolute top-[2px] right-[2px] p-2 z-10 text-primary hover:text-destructive ${className || ""}`}
    >
      <Trash className="w-3.5 h-3.5" />
    </Button>
  );
};

export default DeleteAction;
