import { validate as isValidUUID } from 'uuid';

// we arent using `useRouter` because it initializes with pathname as null even with a path in the url
// we need the contentid right away, why would a render loop be necessary to access a simple path variable?
// because it initializes as null, contentId is null, causing the error in NoteContent to flash
// there is a isReady value exported by the router, but that just feels like a hack on a hack
export const useContentId = () => {
  if (typeof window === "undefined") {
    return "";
  }
  // Get the last segment of the URL path
  const pathSegments = window?.location?.pathname.split("/").filter(Boolean);
  const id = pathSegments[pathSegments.length - 1] || "";
  return isValidUUID(id) ? id : "";
};