import React from "react";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import CollectionDetails from "./CollectionDetails";

interface SaveAsCollectionProps {
  collectionName: string;
  setCollectionName: (collectionName: string) => void;
  collectionColor: string;
  setCollectionColor: (collectionColor: string) => void;
  saveAsCollection: boolean;
  setSaveAsCollection: (saveAsCollection: boolean) => void;
}

const SaveAsCollection: React.FC<SaveAsCollectionProps> = ({
  collectionName,
  setCollectionName,
  collectionColor,
  setCollectionColor,
  saveAsCollection,
  setSaveAsCollection,
}) => {
  return (
    <div
      className="flex items-center space-x-2 pb-4 flex-shrink-0 h-10"
      data-testid="save-as-collection"
    >
      <Switch
        id="save-as-collection"
        checked={saveAsCollection}
        onCheckedChange={setSaveAsCollection}
      />
      <Label
        htmlFor="save-as-collection"
        className="text-muted-foreground flex-shrink-0"
      >
        Save as collection
      </Label>
      {saveAsCollection && (
        <CollectionDetails
          collectionName={collectionName}
          setCollectionName={setCollectionName}
          collectionColor={collectionColor}
          setCollectionColor={setCollectionColor}
        />
      )}
    </div>
  );
};

export default SaveAsCollection;
