import { useCallback, useEffect, useReducer, useState } from "react";
import { CustomEventWithId } from "./RunAllInstructions";
import { useToast } from "@/components/ui/use-toast";
import { usePostCallIdResultsMutation } from "@/api/api";
import { AgentBatchResponse, AgentPollingData, AgentPollingResponse, AgentResponseWrapper, AgentSearchResult } from "@/api/types/agent";
import { useContentId } from "@/hooks/useContentId";

export const INSTRUCTIONS_CUSTOM_EVENT_ADD_POLL = "AddToInstructionsPoll";
export const INSTRUCTIONS_CUSTOM_EVENT_REMOVE_POLL = "RemoveFromInstructionsPoll";
export const INSTRUCTIONS_ADD_SUGGESTION = "AddSuggestion";

export const AGENT_POLL_INTERVAL = 5000
let pollerId: NodeJS.Timeout | null;

interface CallIdAction {
  type: string;
  id: string;
}

interface DataItem {
  call_id: string;
  status: string;
  response: string;
  error: string;
  references: string[];
}

interface Response {
  data: DataItem[];
}

export interface AddSuggestionEvent<T = any>
  extends CustomEvent<T>,
  AddSuggestionDetail { }

export type AddSuggestionDetail = {
  call_id: string;
  status: string;
  response: string;
  error: string | null;
  references: AgentSearchResult[] | null;
};

const init: Set<string> = new Set();

function reducer(state: Set<string>, action: CallIdAction): Set<string> {
  switch (action.type) {
    case "add":
      let newState = new Set(state);
      newState.add(action.id);
      return newState;
    case "remove":
      let newState2 = new Set(state);
      newState2.delete(action.id);
      return newState2;
    default:
      return state;
  }
}
export interface Toast {
  id: string;
  dismiss: () => void;
  update: (props: any) => void;
}

export const useInstructionPoller = () => {
  // use a reducer here, because useState will not update from within an event callback
  const [callIds, setCallIds] = useReducer(reducer, init);
  // keep a reference to the toast so we dont endlessly pop toasts
  const [errorToast, setErrorTost] = useState<Toast | undefined>();
  const { toast } = useToast()
  const [postCallIdResults] = usePostCallIdResultsMutation();
  const contentId = useContentId();

  const dismissToast = () => {
    if (!errorToast) return
    errorToast.dismiss()
    setErrorTost(undefined)
  }

  const processPollResponse = (result: AgentBatchResponse) => {
    if (!result) throw new Error()
    dismissToast()
    Object.values(result).forEach((d: AgentResponseWrapper) => {
      if (d.status === "completed" || d.status === "expired") {
        const detail: AddSuggestionDetail = {
          call_id: d.call_id,
          status: d.status,
          response: d.response.final_result,
          error: d.error,
          references: getAllReferences(result)
        }
        document.dispatchEvent(
          new CustomEvent(INSTRUCTIONS_ADD_SUGGESTION, { detail })
        );
        setCallIds({ type: "remove", id: d.call_id });
      }
    })
  }

  const poll = async () => {
    if (callIds.size === 0) return
    return postCallIdResults({ call_ids: Array.from(callIds) })
      .unwrap()
      .then(processPollResponse)
      .catch(() => {
        // this error sometimes erroneously happens, so we'll just ignore it for now
        // @see PE-1631
        // if (errorToast) return
        // const t = toast({
        //   title: "Error",
        //   description: "Could not fetch Smart Instructions status",
        //   variant: "destructive",
        //   duration: Infinity
        // })
        // setErrorTost(t)
      })
  };

  const addToQueue = (event: Event) => {
    const e = event as CustomEventWithId;
    const id = e.detail.id;
    setCallIds({ type: "add", id });
  };

  const removeFromQueue = (event: Event) => {
    const e = event as CustomEventWithId;
    const id = e.detail.id;
    setCallIds({ type: "remove", id });
  };

  const clearPollInterval = () => {
    if (!pollerId) return
    clearInterval(pollerId)
    pollerId = null
  }

  const pollCallback = useCallback(poll, [callIds, errorToast])

  useEffect(() => {
    document.addEventListener(INSTRUCTIONS_CUSTOM_EVENT_ADD_POLL, addToQueue);
    document.addEventListener(INSTRUCTIONS_CUSTOM_EVENT_REMOVE_POLL, removeFromQueue);
    return () => {
      document.removeEventListener(INSTRUCTIONS_CUSTOM_EVENT_ADD_POLL, addToQueue);
      document.removeEventListener(INSTRUCTIONS_CUSTOM_EVENT_REMOVE_POLL, removeFromQueue);
    };
  });


  useEffect(() => {
    if (callIds.size === 0) {
      clearPollInterval()
      dismissToast()
      return
    }
    clearPollInterval()
    if (callIds.size > 0) {
      pollerId = setInterval(pollCallback, AGENT_POLL_INTERVAL)
    }
  }, [callIds, errorToast, contentId])

  return callIds;

};

const getAllReferences = (data: any) => {
  const allReferences: any[] = [];
  Object.values(data).forEach((d: any) => {
    if (d.response && d.response.step_responses) {
      d.response.step_responses.forEach((step: any) => {
        if (step.search_results) {
          step.search_results.forEach((searchResult: any) => {
            if (searchResult.results) {
              allReferences.push(...searchResult.results);
            }
          });
        }
      });
    }
  });
  return allReferences.sort((a, b) => b.score - a.score);
};