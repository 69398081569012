import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

export const ContentTypeButtons: React.FC<{
  params: any;
  setParams: React.Dispatch<React.SetStateAction<any>>;
  setCollectionParams: React.Dispatch<React.SetStateAction<any>>;
  defaultContentTypeNames: string[];
}> = ({ params, setParams, setCollectionParams, defaultContentTypeNames }) => {
  const isActive = (contentType: string | string[]) => {
    if (Array.isArray(contentType)) {
      return (
        contentType.every((type) => params.content_type_name.includes(type)) &&
        params.content_type_name.length === contentType.length
      );
    }
    return (
      params.content_type_name.includes(contentType) &&
      params.content_type_name.length === 1
    );
  };

  return (
    <>
      <Button
        variant="ghost"
        className={cn(
          isActive(defaultContentTypeNames) ? "bg-muted" : "text-primary/80"
        )}
        onClick={() => {
          setCollectionParams({});
          setParams({
            ...params,
            content_type_name: defaultContentTypeNames,
            text: "",
            offset: 0,
          });
        }}
      >
        Top
      </Button>
      <Button
        variant="ghost"
        className={cn(
          isActive("collection") ? "bg-muted" : "text-muted-foreground"
        )}
        onClick={() => {
          setParams({
            ...params,
            type: "collection",
            content_type_name: ["collection"],
            text: "",
            offset: 0,
          });
          setCollectionParams({
            ...params,
            type: "collection",
            content_type_name: ["collection"],
            text: "",
            offset: 0,
          });
        }}
      >
        Collections
      </Button>
      <Button
        variant="ghost"
        className={cn(
          isActive("codebase") ? "bg-muted" : "text-muted-foreground"
        )}
        onClick={() => {
          setCollectionParams({});
          setParams({
            ...params,
            content_type_name: ["codebase"],
            text: "",
            offset: 0,
          });
        }}
      >
        Codebases
      </Button>
      <Button
        variant="ghost"
        className={cn(
          isActive("supplemental-document")
            ? "bg-muted"
            : "text-muted-foreground"
        )}
        onClick={() => {
          setCollectionParams({});
          setParams({
            ...params,
            content_type_name: ["supplemental-document"],
            text: "",
            offset: 0,
          });
        }}
      >
        PDFs
      </Button>
    </>
  );
};
