import React, { useEffect, useState } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";

interface LanguageSelectProps {
  defaultLanguage: string;
  updateAttributes: (attributes: { language: string }) => void;
  extension: any;
  isDropdownOpen: boolean;
  setIsDropdownOpen: (open: boolean) => void;
}

const LanguageSelect: React.FC<LanguageSelectProps> = ({
  defaultLanguage,
  updateAttributes,
  extension,
  isDropdownOpen,
  setIsDropdownOpen,
}) => {
  const [value, setValue] = useState(defaultLanguage);
  const [query, setQuery] = useState("");

  const languages = extension.options.lowlight.listLanguages();
  const otherLanguages = ["mermaid"].concat(languages).sort();
  const languagesWithAuto = ["auto"].concat(otherLanguages);
  const filteredLanguages = languagesWithAuto.filter((lang: string) =>
    query === "" ? true : lang.toLowerCase().includes(query.toLowerCase())
  );

  const handleLanguageSelect = (lang: string) => {
    setValue(lang);
    updateAttributes({ language: lang });
    setIsDropdownOpen(false);
    setQuery("");
  };

  return (
    <Popover open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          role="combobox"
          aria-expanded={isDropdownOpen}
          className="justify-between text-white pe-1.5"
          onMouseDown={(e) => {
            // prosemirror will try to scroll to the text selection if its offscreen
            // instead of engaging this button so we prevent default behavior
            e.preventDefault();
          }}
        >
          {value || "auto"}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" sideOffset={4} className="w-[180px] p-0">
        <Command>
          <CommandInput
            placeholder="Search language..."
            className="h-9 px-0 border-none ring-0"
          />
          <CommandList>
            <CommandEmpty>No language found.</CommandEmpty>
            <CommandGroup>
              {filteredLanguages.map((lang: string, index: number) => (
                <CommandItem
                  key={index}
                  value={lang}
                  onSelect={(currentValue) => {
                    handleLanguageSelect(currentValue);
                  }}
                >
                  <CheckIcon
                    className={`me-2 ${value === lang ? "" : "opacity-0"}`}
                  />
                  {lang}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default LanguageSelect;
