import React from "react";
import { LibraryRecord } from "@/api/types/library";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { getDocumentIcon } from "@/components/ContentStatus/ContentIconWithStatus";
import { getLabelForContentType, ContentType } from "./useUserDefinedScopeData";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useGetSourceLink } from "@/hooks/useGetSourceLink";
import Link from "next/link";

interface SelectedAssetItemProps {
  asset: LibraryRecord;
  handleRemoveAsset: (assetId: string) => void;
  handleTuner: (asset: LibraryRecord) => void;
}

const SelectedAssetItem: React.FC<SelectedAssetItemProps> = ({
  asset,
  handleRemoveAsset,
  handleTuner,
}) => {
  const sourceLink = useGetSourceLink(asset);
  const isTuned =
    !!asset.children?.length &&
    asset.children?.length > 0 &&
    asset.content_type_name === "codebase";

  return (
    <div key={asset.id} className="flex justify-between items-center p-2 mb-2">
      <div className="flex flex-row justify-center items-center space-x-2">
        <span
          className={`mr-2 ${asset.content_type_name === "supplemental-content" ? "text-red-500" : ""}`}
        >
          {getDocumentIcon(asset.content_type_name)}
        </span>

        <div className="flex flex-col truncate w-full">
          <Link href={sourceLink} className="hover:underline" target="_blank">
            <div className="text-sm font-medium truncate overflow-hidden max-w-[320px]">
              {asset.content_name}
            </div>
          </Link>
          <div className="mt-0.5 text-xs text-muted-foreground line-clamp-1">
            {getLabelForContentType(asset.content_type_name as ContentType)}
            {asset.status !== "generation-complete" && (
              <TooltipProvider delayDuration={0} disableHoverableContent={true}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span className="ml-1 italic hover:text-primary">
                      (Not yet searchable)
                    </span>
                  </TooltipTrigger>
                  <TooltipContent className="text-sm space-y-2" sideOffset={8}>
                    <p>
                      Documentation is generating and will be available soon.
                    </p>
                    <p>
                      {window.location.pathname.includes("/page/")
                        ? "This page"
                        : "Pages"}{" "}
                      will be able to reference it once generation is complete.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-center items-center space-x-0">
        {asset.content_type_name === "codebase" && (
          <Button
            iconOnly
            trailingIcon="AdjustmentsHorizontalIcon"
            variant="ghost"
            className={`p-1.5 text-muted-foreground ${isTuned ? "text-blue-700 dark:text-blue-400 hover:text-blue-700 hover:dark:text-blue-300 hover:bg-blue-100 hover:dark:bg-blue-900/70" : ""}`}
            onClick={() => handleTuner(asset)}
          ></Button>
        )}
        <Button
          iconOnly
          trailingIcon="XMarkIcon"
          variant="ghost"
          className="p-1.5 text-muted-foreground"
          onClick={() => handleRemoveAsset(asset.id)}
        ></Button>
      </div>
    </div>
  );
};

export default SelectedAssetItem;
