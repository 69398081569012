import React from "react";
import { LibraryRecord } from "@/api/types/library";
import SelectedAssetItem from "./SelectedAssetItem";

interface SelectedAssetListProps {
  selectedAssets: LibraryRecord[];
  handleRemoveAsset: (assetId: string) => void;
  handleTuner: (asset: LibraryRecord) => void;
  maxHeight?: string;
}

const SelectedAssetList: React.FC<SelectedAssetListProps> = ({
  selectedAssets,
  handleRemoveAsset,
  handleTuner,
  maxHeight = "254px",
}) => {
  return (
    <>
      <div className="border-t border-border pb-4"></div>
      <h4 className="text-xs font-medium text-muted-foreground pb-2">
        Sources
      </h4>
      <div
        className={`flex-grow overflow-y-scroll`}
        style={{ maxHeight: maxHeight }}
      >
        {selectedAssets.length === 0 && (
          <div className="flex items-center justify-center h-full">
            <p className="text-sm text-muted-foreground">No sources added.</p>
          </div>
        )}
        {selectedAssets
          .sort(
            (a, b) =>
              a.content_type_name.localeCompare(b.content_type_name) ||
              a.content_name.localeCompare(b.content_name)
          )
          .map((asset) => (
            <SelectedAssetItem
              key={asset.id}
              asset={asset}
              handleRemoveAsset={handleRemoveAsset}
              handleTuner={handleTuner}
            />
          ))}
      </div>
    </>
  );
};

export default SelectedAssetList;
