import { toast } from '@/components/ui/use-toast';
import { instructionPromptClassName } from '../Instructions/instruction/Container';
import { InstructionPromptTagName } from '../Instructions/InstructionPromptNode';
import { Group } from './types'
import { v4 as uuidv4 } from "uuid";

const MAX_SMART_INSTRUCTIONS = 20 

export const GROUPS: Group[] = [
  {
    name: 'ai',
    title: 'AI',
    commands: [
      {
        name: 'aiMenu',
        label: 'Smart instruction',
        subLabel: "Generate content automatically",
        iconName: 'Sparkles',
        description: 'Create smart instruction',
        action: editor => {
          const selector = `.${instructionPromptClassName}`;
          const count = document.querySelectorAll(selector)?.length;
          if (count > MAX_SMART_INSTRUCTIONS) {
            toast({
              title: `You've reached the maximum number of Smart Instructions (${MAX_SMART_INSTRUCTIONS})`,
              variant: "destructive",
            });
            return
          }
          const { from, to } = editor.state.selection;
          const id = uuidv4();
          const node = `<${InstructionPromptTagName} identifier='${id}'></${InstructionPromptTagName}>`
          editor
            .chain()
            .insertContentAt(
              from,
              node
            )
            .insertContentAt(from, { type: "paragraph" })
            .focus(from)
            .run();

          setTimeout(() => {
            const el = document.getElementById(id)
            const textarea = el?.getElementsByTagName("textarea")?.[0]
            textarea?.focus();
          }, 50);

        },
      },
    ],
  },
  {
    name: 'headings',
    title: 'Headings',
    commands: [
      
      {
        name: 'heading1',
        label: 'Heading 1',
        iconName: 'Heading1',
        description: 'High priority section title',
        aliases: ['h1'],
        shortcut: '⌘ ⌥ 1',
        action: editor => {
          editor.chain().focus().setHeading({ level: 1 }).run()
        },
      },
      {
        name: 'heading2',
        label: 'Heading 2',
        iconName: 'Heading2',
        description: 'Medium priority section title',
        aliases: ['h2'],
        shortcut: '⌘ ⌥ 2',
        action: editor => {
          editor.chain().focus().setHeading({ level: 2 }).run()
        },
      },
      {
        name: 'heading3',
        label: 'Heading 3',
        iconName: 'Heading3',
        description: 'Low priority section title',
        aliases: ['h3'],
        shortcut: '⌘ ⌥ 3',
        action: editor => {
          editor.chain().focus().setHeading({ level: 3 }).run()
        },
      },
    ],
  },
  {
    name: 'lists',
    title: 'Lists',
    commands: [
      {
        name: 'bulletedList',
        label: 'Bulleted List',
        iconName: 'List',
        description: 'Unordered list of items',
        aliases: ['ul'],
        shortcut: '⌘ ⇧ 8',
        action: editor => {
          editor.chain().focus().toggleBulletList().run()
        },
      },
      {
        name: 'numberedList',
        label: 'Numbered List',
        iconName: 'ListOrdered',
        description: 'Ordered list of items',
        aliases: ['ol'],
        shortcut: '⌘ ⇧ 7',
        action: editor => {
          editor.chain().focus().toggleOrderedList().run()
        },
      },
      {
        name: 'Checklist',
        label: 'Checklist',
        iconName: 'ListTodo',
        description: 'Track tasks with todo items',
        aliases: ['Checklist'],
        shortcut: '⌘ ⇧ 9',
        action: editor => {
          editor.chain().focus().toggleTaskList().run()
        },
      },
    ],
  },
  {
    name: 'formatting',
    title: 'Formatting',
    commands: [
      {
        name: 'codeBlock',
        label: 'Code Block',
        iconName: 'SquareCode',
        description: 'Code block with syntax highlighting',
        shortcut: '⌘ ⌥ C',
        shouldBeHidden: editor => editor.isActive('columns'),
        action: editor => {
          editor.chain().focus().setCodeBlock().run()
        },
      },
      {
        name: 'table',
        label: 'Table',
        iconName: 'Table',
        description: 'Insert a table',
        shouldBeHidden: editor => editor.isActive('columns'),
        action: editor => {
          editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run()
        },
      },
      {
        name: 'divider',
        label: 'Divider',
        iconName: 'Minus',
        description: 'Visually divide content',
        aliases: ['hr'],
        action: editor => {
          editor.chain().focus().setHorizontalRule().run()
        },
      },
      {
        name: 'blockquote',
        label: 'Blockquote',
        iconName: 'Quote',
        description: 'Element for quoting',
        shortcut: '⌘ ⇧ B',
        action: editor => {
          editor.chain().focus().setBlockquote().run()
        },
      },
    ],
  },
]

export default GROUPS

