import { gql } from "@apollo/client";

export const GET_ME = gql`
  query Me {
    me {
      id
    }
  }
`;

export const GET_WORKSPACES = gql`
  query Query($organizationId: String!) {
    organization(id: $organizationId) {
      workspaces {
        id
        display_name
      }
    }
  }
`;

export const GET_WORKSPACES_AND_CODEBASES = gql`
  query Query($organizationId: String!) {
    organization(id: $organizationId) {
      workspaces {
        id
        display_name
        codebases {
          id
          codebase_name
          status
          updated_at
          created_at
        }
      }
    }
  }
`;

export const GET_CODEBASE_NAME = gql`
  query Query($codebaseId: ID!) {
    codebase(id: $codebaseId) {
      codebase_name
      status
    }
  }
`;

export const GET_TREE = gql`
  query Query($codebaseId: ID!, $versionId: ID) {
    tree(codebaseId: $codebaseId, versionId: $versionId) {
      id
      name
      path
      kind
      children
    }
  }
`;

export const GET_TECH_DOC_CONTENT = gql`
  query DocumentSetTechDocContent(
    $nodeKind: NodeType!
    $path: String!
    $workspaceId: ID
    $codebaseId: ID
    $versionId: ID
  ) {
    documentSet(
      nodeKind: $nodeKind
      path: $path
      workspaceId: $workspaceId
      codebaseId: $codebaseId
      versionId: $versionId
    ) {
      short {
        single_sentence
        single_paragraph_document {
          content
          id
        }
      }
      long_document {
        content
        id
      }
      quickstart {
        entry_document {
          content
          id
        }
      }
      code {
        metadata {
          size
          sloc
          extension
          is_binary
          is_hex
          is_analyzable
          is_blacklisted
        }
      }
    }
  }
`;

export const GET_SOURCE_CODE = gql`
  query CodeContent(
    $nodeKind: NodeType!
    $path: String!
    $workspaceId: ID
    $codebaseId: ID
    $versionId: ID
  ) {
    documentSet(
      nodeKind: $nodeKind
      path: $path
      workspaceId: $workspaceId
      codebaseId: $codebaseId
      versionId: $versionId
    ) {
      code {
        content
        extension
        file_name
        metadata {
          size
          sloc
          extension
          is_binary
          is_hex
          is_analyzable
          is_blacklisted
        }
      }
    }
  }
`;

export const GET_SOURCE_CONTENT_SYMBOLS = gql`
  query SymbolsQuery($sourceContentId: ID!, $versionId: ID, $page: Int, $pageSize: Int) {
    symbolSet(
      sourceContentId: $sourceContentId
      versionId: $versionId
      page: $page
      pageSize: $pageSize
    ) {
      pageInfo {
        total
        page
        pageSize
        totalPages
      }
      symbols {
        id
        metadata
      }
    }
  }
`;

export const GET_SOURCE_CONTENT_ID = gql`
query DocumentSetScId(
  $nodeKind: NodeType!
  $path: String!
  $workspaceId: ID
  $codebaseId: ID
  $versionId: ID
  ) {
    documentSet(
    nodeKind: $nodeKind
    path: $path
    workspaceId: $workspaceId
    codebaseId: $codebaseId
    versionId: $versionId
  ) {
    source_content_id
  }
}
`;


export const GET_CONNECTED_GIT_PROVIDERS = gql`
query ConnectedGitProviders {
  connectedGitProviders {
      display_name
      name
      logo_url
  }
}
`;

export const GET_REPOS_BY_GIT_PROVIDER = gql`
query ReposByGitProvider($provider: String!) {
  reposByGitProvider(provider: $provider) {
      provider_name
      repo_name
      org
      last_updated
      metadata
  }
  }
`;

