import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useEffect, useRef } from "react";
import { renderMermaid } from "./mermaidUtils";
import * as marked from "marked";

interface FullScreenDiagramDialogProps {
  showFullScreen: boolean;
  setShowFullScreen: (show: boolean) => void;
  diagram: string;
  summary?: string;
  text?: string;
}

const FullScreenDiagramDialog: React.FC<FullScreenDiagramDialogProps> = ({
  showFullScreen,
  setShowFullScreen,
  diagram,
  summary,
  text,
}) => {
  const fullScreenMermaidRef = useRef(null);

  useEffect(() => {
    if (showFullScreen) {
      setTimeout(() => {
        if (fullScreenMermaidRef.current) {
          renderMermaid(diagram, fullScreenMermaidRef.current, true);
        }
      }, 0);
    }
  }, [showFullScreen, diagram]);

  return (
    <Dialog open={showFullScreen} onOpenChange={setShowFullScreen}>
      <DialogContent className="max-w-[90vw] h-[90vh] flex flex-col overflow-scroll">
        <DialogHeader>
          <DialogTitle>Diagram View</DialogTitle>
          <DialogDescription>
            {summary && (
              <div className="grid grid-cols-2 gap-8 w-full">
                <div
                  className="prose prose-sm w-full max-w-full"
                  dangerouslySetInnerHTML={{ __html: marked.parse(summary) }}
                ></div>
                <div className="tiptap">
                  <pre>{text}</pre>
                </div>
              </div>
            )}
          </DialogDescription>
        </DialogHeader>
        <div>
          <div ref={fullScreenMermaidRef} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FullScreenDiagramDialog;
