import { useDriverTemplate } from "./useDriverTemplate";
import { useGetDocumentSourcesQuery } from "@/api/api";
import { useContentId } from "./useContentId";
import { LibraryRecord } from "@/api/types/library";
import { usePathname } from "next/navigation";

interface SourceReturn {
    sourcePaths: string[];
    isLoadingDocumentSources: boolean;
    hasSources: boolean;
}

const useGetSources = (): SourceReturn => {
    const { isDriverTemplate } = useDriverTemplate();
    const documentId = useContentId();
    const pathname = usePathname();

    const getCodebasePath = () => {
        const treeMatch = pathname?.match(/\/tree\/([^/?]+)/);
        return treeMatch ? treeMatch[1] + '/' : '';
    };
    const codebasePath = getCodebasePath();

    const { data: documentSources, isLoading: isLoadingDocumentSources } = useGetDocumentSourcesQuery(
        { documentId },
        { skip: isDriverTemplate || !documentId || !!codebasePath }
    );

    // If the current page is a tech doc use the codebase name as the source path
    // this will allow codeblocks in tech docs to use the codebase name as the source path
    // if (codebasePath) {
    //     return { sourcePaths: [codebasePath], isLoadingDocumentSources: false, hasSources: true };
    // }

    const sources = documentSources?.results ?? [];
    const sourcePaths = sources
        .filter((source: LibraryRecord) => source.status === "generation-complete")
        .map((source: LibraryRecord) => {
            let path = source.relative_path;
            if (source.content_type_name === "codebase") {
                path += "/";
            }
            return path;
        });
    const hasSources = !isLoadingDocumentSources && sourcePaths.length > 0;
    return { sourcePaths, isLoadingDocumentSources, hasSources };
};

export default useGetSources;