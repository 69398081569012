import { useState, useEffect } from "react";
import { IdToken, useAuth0 } from "@auth0/auth0-react";

interface User {
  userName: string;
  email: string;
  isLoading: boolean;
  isDriverAiUser: boolean | undefined;
}

const useUser = (): User => {
  const { isLoading, getIdTokenClaims, user } = useAuth0();
  const [idTokenClaims, setIdTokenClaims] = useState<IdToken | null>(null);
  const driverAiDomains = ["@driverai.com", "@driver.ai"];
  const isDriverAiUser = driverAiDomains.some((domain) =>
    user?.email?.includes(domain)
  );

  useEffect(() => {
    const fetchIdTokenClaims = async () => {
      try {
        const claims = (await getIdTokenClaims()) || null;
        setIdTokenClaims(claims);
      } catch (error) {
        console.error("Error fetching ID token claims:", error);
      }
    };

    fetchIdTokenClaims();
  }, [getIdTokenClaims]);
  const userName: string = idTokenClaims?.name || "Loading...";
  const email: string = idTokenClaims?.email || "";

  return { userName, email, isLoading, isDriverAiUser };
};

export default useUser;