import { NodeViewProps } from "@tiptap/react";
import { Button } from "@/components/ui/button";
import { INSTRUCTIONS_CUSTOM_EVENT_ADD } from "../RunAllInstructions";
import Prompt from "./Prompt";
import { useEffect, useState, useRef } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import DeleteAction from "./DeleteAction";
import useGetCallId from "./useGetCallId";
import BlockEditorBasic from "@/components/BlockEditor/BlockEditorBasic";

type Props = {
  nodeViewProps: NodeViewProps;
};

const InstructionEdit = (props: Props) => {
  const { nodeViewProps } = props;
  const { node } = nodeViewProps;
  const [prompt, setPrompt] = useState(node.attrs.prompt);
  const rootRef = useRef<HTMLDivElement>(null);
  const { run } = useGetCallId(nodeViewProps);
  const promptRef = useRef<HTMLTextAreaElement>(null);

  const detail = {
    id: node.attrs.identifier,
  };

  const save = () => {
    nodeViewProps.updateAttributes({
      isSaved: true,
      prompt,
    });
    document.dispatchEvent(
      new CustomEvent(INSTRUCTIONS_CUSTOM_EVENT_ADD, { detail })
    );
  };

  const cancel = () => {
    nodeViewProps.updateAttributes({
      isSaved: true,
    });
  };

  const runNewInstruction = () => {
    const updatedAttributes = {
      hasLLMContent: false,
      isSaved: true,
      isLoading: true,
      prompt: prompt,
    };
    nodeViewProps.updateAttributes(updatedAttributes);
    run(updatedAttributes);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        event.preventDefault();
        runNewInstruction();
      }
    };

    const rootElement = rootRef.current;
    if (rootElement) {
      rootElement.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (rootElement) {
        rootElement.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [runNewInstruction]);

  useEffect(() => {
    if (promptRef.current) {
      promptRef.current.focus();
      promptRef.current.setSelectionRange(
        promptRef.current.value.length,
        promptRef.current.value.length
      );
    }
  }, []);

  return (
    <div
      ref={rootRef}
      className="border rounded-lg p-3 pb-2 shadow-lg mx-auto space-y-2"
      data-testid="edit-component"
      tabIndex={0}
    >
      <DeleteAction nodeViewProps={nodeViewProps} node={node} />
      <div>
        <Prompt
          prompt={prompt}
          setPrompt={setPrompt}
          nodeViewProps={nodeViewProps}
          promptRef={promptRef}
        />
      </div>
      <div className="-mx-3 border-t border-solid" />
      <div className="flex items-center justify-end">
        <Button
          variant="secondary"
          onClick={cancel}
          className="mr-2"
          data-testid="instruction-cancel"
        >
          Cancel
        </Button>

        <TooltipProvider delayDuration={300}>
          <Tooltip>
            <TooltipTrigger>
              <Button
                onClick={runNewInstruction}
                variant="ai"
                data-testid="instruction-run"
              >
                Run instruction
              </Button>
            </TooltipTrigger>
            <TooltipContent>⌘ + Enter</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className="-mx-3 border-t border-solid" />
      <div className="p-2">
        <div className="m-0 mb-1 text-xs text-muted-foreground">
          Generated content
        </div>
        <div className="max-h-[275px] overflow-y-auto">
          <BlockEditorBasic
            content={node.attrs.generatedText}
            editable={false}
          />
        </div>
      </div>
    </div>
  );
};

export default InstructionEdit;
