import React from "react";
import { Button } from "@/components/ui/button";
import { Check, Plus } from "lucide-react";
import { LibraryRecord } from "@/api/types/library";
import { ContentIconWithStatus } from "@/components/ContentStatus/ContentIconWithStatus";
import { useGetSourceLink } from "@/hooks/useGetSourceLink";
import Link from "next/link";

interface AssetItemProps {
  asset: LibraryRecord;
  isAdded: boolean;
  handleAddAsset: (asset: LibraryRecord) => void;
  handleRemoveAsset: (assetId: string) => void;
}

const AssetItem: React.FC<AssetItemProps> = ({
  asset,
  isAdded,
  handleAddAsset,
  handleRemoveAsset,
}) => {
  const sourceLink = useGetSourceLink(asset);
  return (
    <div
      key={asset.id}
      className="flex justify-between items-center py-2 px-1 w-full hover:bg-muted cursor-pointer"
      onClick={() => !isAdded && handleAddAsset(asset)}
    >
      <div className="flex items-center truncate w-full">
        <ContentIconWithStatus content={asset} />
        <div className="text-sm font-medium truncate">
          {asset.content_name}
          {asset.version && (
            <span className="text-sm text-muted-foreground ml-2">
              {asset.version}
            </span>
          )}
        </div>
      </div>
      {isAdded ? (
        <Button
          size="xs"
          className="px-2 py-1 text-xs border rounded"
          onClick={() => handleRemoveAsset(asset.id)}
        >
          <Check className="w-4 h-4 mr-1" /> Added
        </Button>
      ) : (
        <Button
          variant="outline"
          size="xs"
          className="px-2 py-1 text-xs border rounded"
          onClick={() => handleAddAsset(asset)}
        >
          <Plus className="w-4 h-4 mr-1" /> Add
        </Button>
      )}
    </div>
  );
};

export default AssetItem;
