import React from "react";
import { Button } from "@/components/ui/button";
import { Check, Loader, Plus } from "lucide-react";
import { LibraryRecord } from "@/api/types/library";
import { getDocumentIcon } from "@/components/ContentStatus/ContentIconWithStatus";
import { useLazyGetCollectionSourcesQuery } from "@/api/api";
import { useToast } from "@/components/ui/use-toast";

interface CollectionItemProps {
  asset: LibraryRecord;
  isAdded: boolean;
  handleAddAsset: (asset: LibraryRecord) => void;
  handleRemoveAsset: (assetId: string) => void;
}

const CollectionItem: React.FC<CollectionItemProps> = ({
  asset,
  isAdded,
  handleAddAsset,
  handleRemoveAsset,
}) => {
  const [getCollectionSources, { isLoading }] =
    useLazyGetCollectionSourcesQuery();
  const { toast } = useToast();

  const associateCollection = (asset: LibraryRecord) => {
    getCollectionSources({ collectionId: asset.id })
      .then((res) => {
        if (res.error) throw res.error;
        res.data?.results?.forEach((source) => {
          handleAddAsset(source);
        });
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: "Error fetching collection sources",
          variant: "destructive",
        });
      });
  };

  return (
    <div
      key={asset.id}
      className="flex justify-between items-center py-2 px-1 w-full h-12 hover:bg-muted cursor-pointer"
      onClick={() => !isAdded && associateCollection(asset)}
    >
      <div className="flex items-center truncate w-full">
        <span
          className={`mr-2 ${asset.content_type_name === "pdf" ? "text-red-500" : ""}`}
        >
          {getDocumentIcon(asset.content_type_name)}
        </span>
        <div className="text-sm font-medium truncate">{asset.content_name}</div>
      </div>
      {isAdded ? (
        <Button
          size="xs"
          className="px-2 py-1 text-xs border rounded"
          onClick={() => handleRemoveAsset(asset.id)}
        >
          <Check className="w-4 h-4 mr-1" /> Added
        </Button>
      ) : (
        <Button
          variant="outline"
          size="xs"
          className="px-2 py-1 text-xs border rounded"
          onClick={() => associateCollection(asset)}
        >
          {!isLoading ? (
            <>
              <Plus className="w-4 h-4 mr-1" />
              Add
            </>
          ) : (
            <Loader className="w-4 h-4 mr-1 animate-spin" />
          )}
        </Button>
      )}
    </div>
  );
};

export default CollectionItem;
