import { useState, useEffect, useRef } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useQuery } from "@apollo/client";
import { GET_TREE, GET_SOURCE_CODE } from "@/graphql/queries";
import { editor } from "monaco-editor";
import Image from "next/image";
import { useTheme } from "next-themes";
import { useSyncSearchParams } from "@/hooks/useSyncSearchParams";

const MonacoEditor = dynamic(() => import("@monaco-editor/react"), {
  ssr: false,
});

interface SourceCodeProps {
  workspaceId?: string;
  codebaseId?: string;
  versionId?: string;
  path?: string;
  nodeKind?: string;
}

const SourceCode = ({
  workspaceId: propWorkspaceId,
  codebaseId: propCodebaseId,
  path: propPath,
  nodeKind: propNodeKind,
}: SourceCodeProps) => {
  const router = useRouter();
  const {
    workspaceId: routerWorkspaceId,
    codebaseId: routerCodebaseId,
    path: routerPath,
  } = router.query;

  // Use props if provided, otherwise fall back to router values
  const workspaceId = propWorkspaceId || routerWorkspaceId;
  const codebaseId = propCodebaseId || routerCodebaseId;
  const path = propPath || routerPath;

  const [code, setCode] = useState("");
  const [language, setLanguage] = useState("");
  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
  const [loading, setLoading] = useState(true);
  const [editorReady, setEditorReady] = useState(false);

  const { resolvedTheme } = useTheme();
  const [theme, setTheme] = useState("vs-dark");

  const handleEditorWillMount = (monaco: {
    languages: {
      typescript: {
        typescriptDefaults: {
          setDiagnosticsOptions: (arg0: {
            noSemanticValidation: boolean;
            noSyntaxValidation: boolean;
            diagnosticCodesToIgnore: number[];
          }) => void;
        };
      };
    };
  }) => {
    monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: true,
      noSyntaxValidation: false,
      diagnosticCodesToIgnore: [7027],
    });
  };

  // Only run tree query if nodeKind is not provided via props
  const { data: treeData } = useQuery(GET_TREE, {
    variables: { codebaseId },
    skip: !!propNodeKind, // Skip the query if nodeKind is provided via props
  });

  // Use the provided nodeKind or calculate it from tree as before
  let nodeKind: string | null = propNodeKind || null;
  let nodePath: string | null = propPath || null;

  // Only process tree data if nodeKind wasn't provided via props
  if (!propNodeKind && treeData && treeData.tree) {
    if (Array.isArray(path)) {
      const pathToMatch = path.join("/");
      if (treeData && treeData.tree) {
        for (const node of treeData.tree) {
          const normalizedNodePath = node.path.startsWith("/")
            ? node.path.slice(1)
            : node.path;
          const normalizedNodePathWithoutTrailingSlash =
            normalizedNodePath.endsWith("/")
              ? normalizedNodePath.slice(0, -1)
              : normalizedNodePath;
          if (normalizedNodePathWithoutTrailingSlash === pathToMatch) {
            nodeKind = node.kind;
            nodePath = node.path;
            break;
          }
        }
      }
    }
  }

  const params = useSyncSearchParams();

  const { data: fileData } = useQuery(GET_SOURCE_CODE, {
    variables: {
      nodeKind,
      path: nodePath,
      workspaceId,
      codebaseId,
      versionId: params?.get("versionId") || null,
    },
  });
  // TODO - add more languages OR detect automatically
  const mapExtensionToLanguage = (extension: string) => {
    const languageMap: { [key: string]: string } = {
      abap: "abap",
      apex: "apex",
      azcli: "azcli",
      bat: "bat",
      bicep: "bicep",
      c: "c",
      cameligo: "cameligo",
      clj: "clojure",
      cob: "cobol",
      coffee: "coffeescript",
      cpp: "cpp",
      cs: "csharp",
      csp: "csp",
      css: "css",
      cypher: "cypher",
      dart: "dart",
      dockerfile: "dockerfile",
      fs: "fsharp",
      go: "go",
      gql: "graphql",
      h: "c",
      handlebars: "handlebars",
      hcl: "hcl",
      html: "html",
      ini: "ini",
      java: "java",
      js: "javascript",
      jsx: "javascript",
      json: "json",
      kt: "kotlin",
      less: "less",
      lua: "lua",
      md: "markdown",
      m3: "m3",
      mysql: "mysql",
      m: "objectivec",
      pas: "pascal",
      pl: "perl",
      pgsql: "pgsql",
      php: "php",
      pug: "pug",
      py: "python",
      r: "r",
      razor: "razor",
      redis: "redis",
      redshift: "redshift",
      rb: "ruby",
      rs: "rust",
      sb: "sb",
      scm: "scheme",
      scss: "scss",
      sh: "shell",
      sol: "solidity",
      sql: "sql",
      st: "st",
      swift: "swift",
      sv: "systemverilog",
      v: "verilog",
      ts: "typescript",
      tsx: "typescript",
      vb: "vb",
      xml: "xml",
      yaml: "yaml",
    };

    return languageMap[extension] || "plaintext";
  };

  useEffect(() => {
    if (fileData && fileData.documentSet && fileData.documentSet.code) {
      setCode(fileData.documentSet.code.content);
      setLanguage(mapExtensionToLanguage(fileData.documentSet.code.extension));
      setLoading(false);
    }
  }, [fileData]);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setValue(code);
      editorRef.current.setPosition({ lineNumber: 1, column: 1 });
      editorRef.current.revealLine(1);
      editorRef.current.setScrollTop(0);
    }
  }, [code]);

  useEffect(() => {
    setTheme(resolvedTheme === "dark" ? "vs-dark" : "vs");
  }, [resolvedTheme]);

  // highlight code based on the URL hash
  useEffect(() => {
    if (!editorReady) return;

    const updateSelectionFromURL = () => {
      const hash = window.location.hash;
      const match = hash.match(/#L(\d+)(?:-L(\d+))?/);

      if (match) {
        const startLine = parseInt(match[1], 10);
        const endLine = match[2] ? parseInt(match[2], 10) : startLine;

        if (editorRef.current) {
          const selectionRange = {
            startLineNumber: startLine,
            startColumn: 1,
            endLineNumber: endLine,
            endColumn:
              editorRef.current.getModel()?.getLineMaxColumn(endLine) || 1,
          };
          editorRef.current.revealRangeInCenter(selectionRange);
          editorRef.current.setSelection(selectionRange);
        }
      }
    };

    updateSelectionFromURL();

    const handleHashChange = () => {
      updateSelectionFromURL();
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [editorReady]);

  if (loading) {
    const widths = [
      "w-24",
      "w-48",
      "w-56",
      "w-20",
      "w-40",
      "w-52",
      "w-32",
      "w-44",
    ];
    return (
      <div className="flex flex-col h-full space-y-4 px-6 py-4 text-left">
        {widths.map((width, index) => (
          <div key={index} className="flex space-x-4">
            <div className="animate-pulse h-4 w-4 rounded bg-neutral-200 dark:bg-neutral-500 flex-shrink-0"></div>
            <div
              className={`animate-pulse h-4 ${width} rounded bg-neutral-200 dark:bg-neutral-500`}
            ></div>
          </div>
        ))}
      </div>
    );
  }

  if (!code) {
    return (
      <div className="flex flex-col items-center justify-center h-full space-y-4 px-8 text-center">
        <Image
          src="/logomark_driver.webp"
          alt="Driver"
          className="w-16 h-auto dark:invert dark:opacity-80"
          width={56}
          height={56}
          priority
        />
        <h1 className="text-xl font-bold text-foreground dark:text-neutral-100">
          Did your code fast-forward too far?
        </h1>
        <p className="text-base text-muted-foreground dark:text-neutral-300">
          It seems like we&apos;ve driven past it.
        </p>
      </div>
    );
  }

  return (
    <MonacoEditor
      height="100%"
      language={language}
      value={code}
      theme={theme}
      beforeMount={handleEditorWillMount}
      onMount={(editor) => {
        editorRef.current = editor;
        setEditorReady(true); // Indicate that the editor is ready
        if (/Mobi|Android/i.test(navigator.userAgent)) {
          editor.updateOptions({ fontSize: 16 });
        }
      }}
      options={{
        readOnly: true,
        lineNumbers: "on",
        scrollBeyondLastLine: false,
        minimap: { enabled: false },
        wordWrap: "on",
        padding: { top: 10, bottom: 10 },
      }}
    />
  );
};

export default SourceCode;
