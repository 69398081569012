import * as marked from "marked";
import he from "he";
import { LibraryRecord } from "@/api/types/library";
import { deprecate } from "util";

marked.setOptions({
  breaks: true,
});

const isJsonString = (content: any): boolean => {
  try {
    JSON.parse(content);
    return true;
  } catch (error) {
    return false;
  }
};

const isEncoded = (content: any): boolean => {
  try {
    const decoded = he.decode(content, {
      strict: true,
    });
    if (decoded === content) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

const isMarkdown = (content: any): boolean => {
  try {
    const parsed = marked.parse(content, { silent: false });
    if (parsed === content) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * @deprecated Make this a private function and use transformLibraryContent instead
 */
export const formatEditorContent = (content: any): any => {
  if (typeof content === "string") {
    const decodedContent = he.decode(content);

    if (isJsonString(decodedContent)) {
      return JSON.parse(decodedContent);
    } else {
      return marked.parse(decodedContent).toString();
    }
  }
  return content;
};


export const transformLibraryContent = (
  record: LibraryRecord | { content: any, content_name: any } | undefined | null
): any => {
  if (!record) return { name: "", content: "" };
  if (record.content === " ") return { name: record.content_name, content: "" };
  if (record.content === "") return { name: record.content_name, content: "" };
  const json = isJsonString(record.content);
  const markdown = isMarkdown(record.content);
  const encoded = isEncoded(record.content);

  if (json) {
    const parsedContent = JSON.parse(record?.content);
    const encoded = isEncoded(parsedContent?.content);
    if(encoded) {
      const decoded = he.decode(parsedContent?.content);
      const json = isJsonString(decoded);
      if(json) {
        const parsedContent = JSON.parse(decoded);
        if(parsedContent.type === "doc") {
          return { name: record?.content_name, content: parsedContent };
        }
        return { name: record?.content_name, content: parsedContent.content };
      }
      return { name: record?.content_name, content: decoded };
    }
    if (parsedContent.type === "doc") {
      return { name: record?.content_name, content: parsedContent };
    }
    return { name: record?.content_name, content: parsedContent.content };
  } else if (encoded) {
    const decoded = he.decode(record.content);
    const json = isJsonString(decoded);
    if (json) {
      const parsedContent = JSON.parse(decoded)
      if (parsedContent.type === "doc") {
        return { name: record.content_name, content: parsedContent };
      }
      return { name: record.content_name, content: parsedContent.content };
    }
    return { name: record.content_name, content: decoded };
  }
  else if (markdown) {
    const content = marked.parse(record.content).toString();
    return { name: record?.content_name, content: content };
  }
  return { name: record?.content_name, content: record?.content };
};
