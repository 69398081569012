export interface SmartInstructionExample {
  id: string;
  instruction: string;
  category: 'summary' | 'explanation' | 'comparison' | 'analysis' | 'guide' | 'examples';
}

export const smartInstructionExamples: SmartInstructionExample[] = [
  {
    id: "1",
    instruction: "Summarize the key points of this document, highlighting the main ideas and their significance.",
    category: 'summary',
  },
  {
    id: "2",
    instruction: "Explain the concept of [specific topic] in simple terms, using analogies where appropriate.",
    category: 'explanation',
  },
  {
    id: "3",
    instruction: "Compare and contrast the advantages and disadvantages of [approach A] versus [approach B].",
    category: 'comparison',
  },
  {
    id: "4",
    instruction: "Analyze the potential impact of [recent development or trend] on [specific field or industry].",
    category: 'analysis',
  },
  {
    id: "5",
    instruction: "Create a step-by-step guide for implementing [specific process or technique].",
    category: 'guide',
  },
  {
    id: "6",
    instruction: "Provide three practical examples of how [concept or technology] can be applied in real-world scenarios.",
    category: 'examples',
  },
  {
    id: "7",
    instruction: "Outline the key factors to consider when evaluating [type of solution or product].",
    category: 'analysis',
  },
  {
    id: "8",
    instruction: "Explain the relationship between [concept A] and [concept B], and how they influence each other.",
    category: 'explanation',
  },
  {
    id: "9",
    instruction: "Summarize the key features of [technology or field], highlighting their significance.",
    category: 'summary',
  },
  {
    id: "10",
    instruction: "Create a troubleshooting guide for common issues encountered when working with [specific tool or technology].",
    category: 'guide',
  },
  {
    id: "11",
    instruction: "Compare the features and capabilities of [tool A], [tool B], and [tool C] for [specific use case].",
    category: 'comparison',
  },
  {
    id: "12",
    instruction: "Provide examples of best practices for [specific process or methodology].",
    category: 'examples',
  },
  {
    id: "13",
    instruction: "Analyze the potential risks and mitigation strategies associated with [specific approach or technology].",
    category: 'analysis',
  },
  {
    id: "14",
    instruction: "Explain the importance of [specific practice or principle] in [broader field or context].",
    category: 'explanation',
  },
  {
    id: "15",
    instruction: "Summarize the key takeaways from [specific report, article, or presentation].",
    category: 'summary',
  },
];
