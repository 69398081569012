import React, { useMemo } from "react";
import { Sparkles, Zap, Target, CornerDownRight } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  smartInstructionExamples,
  SmartInstructionExample,
} from "@/data/smartInstructionExamples";

interface SmartInstructionItemProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const SmartInstructionItem: React.FC<SmartInstructionItemProps> = ({
  icon,
  title,
  description,
}) => (
  <div className="flex">
    <div className="flex-shrink-0">
      <div className="flex items-center justify-center w-8 h-fit p-1.5 rounded-md bg-background text-primary border border-border">
        {icon}
      </div>
    </div>
    <div className="ml-3 flex flex-col justify-center">
      <h3 className="text-sm font-medium text-foreground">{title}</h3>
      <p className="mt-1 text-xs text-muted-foreground">{description}</p>
    </div>
  </div>
);

interface ExampleInstructionProps {
  example: SmartInstructionExample;
  onInsert: (instruction: string) => void;
}

const ExampleInstruction: React.FC<ExampleInstructionProps> = ({
  example,
  onInsert,
}) => {
  return (
    <div
      className="flex items-center justify-between p-2 bg-muted rounded-md cursor-pointer group hover:bg-muted-foreground/10 transition-colors"
      onClick={() => onInsert(example.instruction)}
    >
      <p className="text-xs text-primary">{example.instruction}</p>
      <span className="text-xs text-primary opacity-0 group-hover:opacity-100 transition-opacity flex items-center">
        <CornerDownRight className="w-4 h-4 mr-1" />
        Use
      </span>
    </div>
  );
};

interface SmartInstructionInfoProps {
  onInsert: (instruction: string) => void;
  numExamples?: number;
}

const SmartInstructionInfo: React.FC<SmartInstructionInfoProps> = ({
  onInsert,
  numExamples = 3,
}) => {
  const randomExamples = useMemo(() => {
    const shuffled = [...smartInstructionExamples].sort(
      () => 0.5 - Math.random()
    );
    return shuffled.slice(0, numExamples);
  }, [numExamples]);

  return (
    <div className="p-4">
      <h2 className="text-lg font-bold text-foreground mb-2">
        Smart Instructions
      </h2>
      <p className="text-sm text-muted-foreground mb-3">
        Use smart instructions to generate content that references your sources.
      </p>
      <Tabs defaultValue="about">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="about">About</TabsTrigger>
          <TabsTrigger value="examples">Examples</TabsTrigger>
        </TabsList>
        <TabsContent value="about">
          <div className="space-y-3 mt-3">
            <SmartInstructionItem
              icon={<Sparkles className="h-4 w-4" />}
              title="AI-Powered Content"
              description="Generate content using AI based on your added sources."
            />
            <SmartInstructionItem
              icon={<Zap className="h-4 w-4" />}
              title="Quick Generation"
              description="Quickly create content by providing clear instructions."
            />
            <SmartInstructionItem
              icon={<Target className="h-4 w-4" />}
              title="Contextual Accuracy"
              description="Get accurate results by referencing specific sources."
            />
          </div>
        </TabsContent>
        <TabsContent value="examples">
          <div className="space-y-2 mt-3">
            {randomExamples.map((example) => (
              <ExampleInstruction
                key={example.id}
                example={example}
                onInsert={onInsert}
              />
            ))}
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default SmartInstructionInfo;
