import { DriverTreeNode } from "./DriverTuner";
import { memoize } from "lodash";

export interface FlatNode {
    __typename: string;
    id: string;
    name: string;
    path: string;
    kind: string;
    children: FlatNode[];
}

export function buildTreeData(nodes: FlatNode[], codebaseId: string): DriverTreeNode | null {
    if (!nodes || nodes.length === 0) return null;
    if (!codebaseId) return null;
    const copy = [...nodes];
    const sorter = memoize((arr: FlatNode[]) => arr.sort((a, b) => a.path.length - b.path.length), () => codebaseId);
    const nodeList = sorter(copy)
    let root: DriverTreeNode | null = null;
    const pathToNode: { [key: string]: DriverTreeNode } = {};

    nodeList.forEach((node) => {
        const pathParts = node.path.replace(/\/$/, "").split("/");
        const parentPath = pathParts.slice(0, -1).join("/");
        const currentNode: DriverTreeNode = {
            id: node.id,
            name: node.name,
            kind: node.kind,
        };

        if (!parentPath) {
            root = currentNode;
            root.id = codebaseId
        } else {
            const parentNode = pathToNode[parentPath];
            if (parentNode) {
                if (parentNode.children) {
                    parentNode.children.push(currentNode);
                } else {
                    parentNode.children = [currentNode];
                }
            }
        }

        // Map this node's path to the node itself
        pathToNode[node.path.replace(/\/$/, "")] = currentNode;
    });
    return root
}
