import { LibraryRecord } from "@/api/types/library";
import { useContentId } from "./useContentId";
import driverTemplatesEl from "@/components/Templates/DriverTemplates.json";
const driverTemplates = driverTemplatesEl as unknown as LibraryRecord[];
import { usePathname } from "next/navigation";

interface Metadata {
  background: string;
  color: string;
}

type MetadataDict = {
  [key: string]: Metadata;
};

interface UseDriverTemplateReturn {
  isTemplate: boolean;
  isPage: boolean;
  isPreview: boolean;
  isDriverTemplate: boolean;
  driverTemplate: LibraryRecord | undefined;
  metadata: Metadata | undefined;
}

/**
 * Hook to determine if a note is a driver template and return the metadata for the template
 * To update driver templates based on edits to the pages tagged "DriverTemplate", update the driverTemplates array in the DriverTemplates.json file
 * Grab the results from the fetch call made on this page
 * http://localhost:3000/driver-ai/library?columnFilters=%5B%7B%22id%22%3A%22tags%22%2C%22value%22%3A%5B%226a4069bf-07a2-453f-9fc0-e5e88871f66b%22%5D%7D%5D
 * and paste them into the DriverTemplates.json file
 * @param id - The id of the note
 * @returns - The metadata for the template
 */
export const useDriverTemplate = (id?: string): UseDriverTemplateReturn => {
  const nodeId = useContentId();
  const itemId = id ? id : nodeId;
  const path = usePathname();
  const isTemplate = path?.includes("/template/");
  const isPage = path?.includes("/page/");
  const isPreview = !isTemplate && !isPage;
  const isDriverTemplate =
    !isPage && driverTemplates.some((template) => template.id === itemId);

  const metadata: MetadataDict = {
    // API Reference Guide
    "704b776d-468f-49b1-be04-6337eeb7a45c": {
      background: "#E9D6AC",
      color: "#171717",
    },
    // Business Logic 101
    "27b849a1-907b-4a6b-87b2-a1eded6045f5": {
      background: "#171717",
      color: "#F1F1F1",
    },
    // Basic Readme
    "d080ff77-2d89-405d-8a49-9dda9d87c43e": {
      background: "#091E4D",
      color: "#F1F1F1",
    },
    // Codebase Starter
    "9bdf3110-73e3-4ed9-9997-ac4a9da00e98": {
      background: "#F1F1F1",
      color: "#171717",
    },
  };

  return {
    isTemplate,
    isPage,
    isPreview,
    isDriverTemplate,
    driverTemplate: driverTemplates.find((template) => template.id === itemId),
    metadata: metadata?.[itemId as keyof MetadataDict],
  };
};
