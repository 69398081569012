import { NodeViewProps } from "@tiptap/react";
import { v4 as uuidv4 } from "uuid";
import { Button } from "@/components/ui/button";
import {
  CornerDownLeft,
  Globe,
  Play,
  Trash,
  User,
  InfoIcon,
} from "lucide-react";

import {
  INSTRUCTIONS_CUSTOM_EVENT_ADD,
  INSTRUCTIONS_CUSTOM_EVENT_REMOVE,
} from "../RunAllInstructions";
import { InstructionPromptTagName } from "../InstructionPromptNode";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import useGetCallId from "./useGetCallId";
import Prompt from "./Prompt";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useEffect, useRef, useState } from "react";
import DeleteAction from "./DeleteAction";
import { LibraryRecord } from "@/api/types/library";
import Sources from "./Sources";
import AddSourceButton from "./AddSourceButton";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import SmartInstructionInfo from "@/components/Guides/SmartInstructionInfo";
import useGetSources from "@/hooks/useGetSources";

type Props = {
  nodeViewProps: NodeViewProps;
};

const Create = (props: Props) => {
  const { nodeViewProps } = props;
  const { editor, node } = nodeViewProps;
  const { run } = useGetCallId(nodeViewProps);
  const [open, setOpen] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const { hasSources } = useGetSources();

  const detail = {
    id: node.attrs.identifier,
  };
  const disabled = node.attrs.prompt === "";

  const positionCursor = () => {
    const endPos = nodeViewProps.getPos() + node.nodeSize;
    editor.commands.focus("start");
    editor
      .chain()
      .insertContentAt(endPos, { type: "paragraph" })
      .focus(endPos)
      .run();
  };

  const setPrompt = (value: string) => {
    nodeViewProps.updateAttributes({
      prompt: value,
    });
  };

  const setAddMore = () => {
    nodeViewProps.updateAttributes({
      addMore: !node.attrs.addMore,
    });
  };

  const runInstruction = () => {
    addInstruction();
    run();
  };

  const createNewInstruction = () => {
    const cursorLine = editor.view.state.selection.$anchor;
    const position = cursorLine.pos + 1;
    const id = uuidv4();
    const node = `<${InstructionPromptTagName} identifier='${id}' addMore="true"></${InstructionPromptTagName}>`;
    editor.chain().insertContentAt(position, node).focus(position).run();
    setTimeout(() => {
      const el = document.getElementById(id);
      const textarea = el?.getElementsByTagName("textarea")?.[0];
      textarea?.focus();
    }, 50);
  };

  const addInstruction = () => {
    nodeViewProps.updateAttributes({
      isSaved: true,
      isCreated: false,
    });
    document.dispatchEvent(
      new CustomEvent(INSTRUCTIONS_CUSTOM_EVENT_ADD, { detail })
    );
    positionCursor();
    if (node.attrs.addMore) {
      createNewInstruction();
      nodeViewProps.updateAttributes({
        addMore: false,
      });
    }
  };

  const handleSources = (sources: LibraryRecord[]) => {
    nodeViewProps.updateAttributes({
      sources,
    });
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (disabled) return;
      const isMeta = event.metaKey || event.ctrlKey;
      if (isMeta && event.key === "Enter") {
        event.preventDefault();
        if (event.shiftKey && hasSources) {
          runInstruction();
        } else {
          addInstruction();
        }
      }
    };

    const rootElement = rootRef.current;
    if (rootElement) {
      rootElement.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (rootElement) {
        rootElement.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [disabled, runInstruction, addInstruction]);

  return (
    <div
      ref={rootRef}
      className="relative border rounded-lg p-3 pb-2 shadow-lg mx-auto space-y-2"
      data-testid="create-component"
      tabIndex={0}
    >
      <DeleteAction nodeViewProps={nodeViewProps} node={node} />
      <Prompt
        prompt={node.attrs.prompt}
        setPrompt={setPrompt}
        nodeViewProps={nodeViewProps}
      />
      {/* TODO: Design UI and user flow for adding sources */}
      {/* <AddSourceButton
        nodeViewProps={nodeViewProps}
        open={open}
        setOpen={setOpen}
        handleSources={handleSources}
        sources={node.attrs.sources}
      />
      {node.attrs.sources.length > 0 && (
        <Sources nodeViewProps={nodeViewProps} />
      )} */}
      <div className="-mx-3 border-t border-solid" />
      <div className="flex justify-between items-center">
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="ghost" size="sm" className="text-muted-foreground">
              <InfoIcon className="w-4 h-4 mr-2" />
              How to use
            </Button>
          </PopoverTrigger>
          <PopoverContent side="bottom" align="start" className="w-[400px] p-0">
            <SmartInstructionInfo onInsert={setPrompt} />
          </PopoverContent>
        </Popover>
        <div className="flex justify-end">
          {/* NOTE: Removing this until there is a use case for it */}
          {/* <div className="flex items-center">
            <Switch
              id="create-more"
              checked={node.attrs.addMore}
              data-testid="instruction-add-more"
              onCheckedChange={setAddMore}
              className="mr-2"
            />
            <Label htmlFor="create-more">Create more</Label>
          </div> */}
          <div className="flex items-center space-x-2">
            {/* NOTE: Removing this until there is a use case for it */}
            {/* <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    onClick={runInstruction}
                    variant="ghost"
                    disabled={disabled}
                    data-testid="instruction-run"
                  >
                    <Play className="w-4 h-4 mr-1" />
                    Run
                  </Button>
                </TooltipTrigger>
                <TooltipContent>⌘ + Return</TooltipContent>
              </Tooltip>
            </TooltipProvider> */}
            <TooltipProvider delayDuration={300}>
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    onClick={addInstruction}
                    variant="ai"
                    disabled={disabled}
                    data-testid="instruction-add"
                    className="gap-x-2"
                  >
                    Add instruction
                    <CornerDownLeft className="w-4 h-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>⌘ + Enter</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
