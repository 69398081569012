import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import AssetList from "./AssetList";
import {
  combineAssets,
  useUserDefinedScopeData,
} from "./useUserDefinedScopeData";
import DriverTuner from "./DriverTuner";
import { LibraryRecord } from "@/api/types/library";
import { isEqual } from "lodash";
import AssetManagementPanel from "./AssetManagementPanel";
import CreateCollectionPanel from "./CreateCollectionPanel";

interface UserDefinedScopeProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  isTunerOpen?: boolean;
  tunerItemRecord?: LibraryRecord | null;
  onTunerAccept?: (checked: string[]) => void;
  onSubmit?: (LibraryRecord: LibraryRecord[]) => void;
  sources?: LibraryRecord[];
  isSmartInstruction?: boolean;
  createCollection?: boolean;
  editCollection?: boolean;
  collectionId?: string;
}

const UserDefinedScope: React.FC<UserDefinedScopeProps> = ({
  open,
  setOpen,
  isTunerOpen,
  tunerItemRecord,
  onTunerAccept,
  onSubmit,
  sources,
  isSmartInstruction,
  createCollection,
  editCollection,
  collectionId,
}) => {
  const [tunerOpen, setTunerOpen] = useState(isTunerOpen || false);
  const [tunerItem, setTunerItem] = useState<LibraryRecord | null>(
    tunerItemRecord || null
  );
  const [collectionColor, setCollectionColor] = useState("#6B7280");
  const {
    selectedAssets,
    handleAddAsset,
    handleRemoveAsset,
    handleSubmit,
    saveAsCollection,
    setSaveAsCollection,
    collectionName,
    setCollectionName,
    isSubmitting,
    setSelectedAssets,
    documentSourcesData,
  } = useUserDefinedScopeData({
    sources,
    setOpen,
    isSmartInstruction,
    collectionColor,
    editCollection,
    collectionId,
  });

  const hasProvidedSources = (sources?.length ?? 0) > 0;
  const hasDocumentSources =
    (documentSourcesData?.results?.length ?? 0) > 0 && !sources;
  const hasSources = hasProvidedSources || hasDocumentSources;
  const shouldRemoveAll = hasSources && selectedAssets.length === 0;

  const areSelectedAssetsDifferent = () => {
    const newAssets = combineAssets(selectedAssets);
    const originalValues = [
      ...(sources ?? []),
      ...(documentSourcesData?.results ?? []),
    ];
    const originalAssets = combineAssets(originalValues);
    const originalIds = originalAssets.map((asset) => asset.source_content_id);
    const newAssetsIds = newAssets.map((asset) => asset.source_content_id);
    return !isEqual(new Set(newAssetsIds), new Set(originalIds));
  };

  const sourcesAreChanged = areSelectedAssetsDifferent();

  const handleTunerSubmit = () => {
    setTunerOpen(false);
  };

  const handleTuner = (asset: LibraryRecord) => {
    setTunerItem(asset);
    setTunerOpen(true);
  };

  const modalClosed = (open: boolean) => {
    if (!open) {
      setTunerOpen(false);
      setTunerItem(null);
      setOpen(false);
      setSelectedAssets([]);
    }
  };

  useEffect(() => {
    if (isTunerOpen) {
      setTunerOpen(true);
    }
    if (tunerItemRecord) {
      setTunerItem(tunerItemRecord);
    }
  }, [isTunerOpen, tunerItemRecord]);

  const disabledCollection = !collectionName && saveAsCollection;
  const addAssetsDisabled =
    isSubmitting ||
    (!sourcesAreChanged && disabledCollection) ||
    (!sourcesAreChanged && !saveAsCollection);

  return (
    <div>
      <Dialog open={open} onOpenChange={modalClosed}>
        <DialogContent className="p-0 min-h-[500px] max-w-[1168px]">
          <div className="hidden">
            <DialogHeader>
              <DialogTitle>Add Document Sources</DialogTitle>
              <DialogDescription>
                Add document sources to your document.
              </DialogDescription>
            </DialogHeader>
          </div>
          <div className="flex max-w-[1160px]">
            <div className="w-1/2 border-r border-border relative">
              {tunerOpen && (
                <div className="absolute -inset-[1px] bg-black bg-opacity-10 backdrop-blur-md z-10"></div>
              )}
              <AssetList
                selectedAssets={selectedAssets}
                handleAddAsset={handleAddAsset}
                handleRemoveAsset={handleRemoveAsset}
              />
            </div>
            {!tunerOpen && !(createCollection || editCollection) && (
              <AssetManagementPanel
                collectionName={collectionName}
                setCollectionName={setCollectionName}
                collectionColor={collectionColor}
                setCollectionColor={setCollectionColor}
                saveAsCollection={saveAsCollection}
                setSaveAsCollection={setSaveAsCollection}
                selectedAssets={selectedAssets}
                handleRemoveAsset={handleRemoveAsset}
                handleTuner={handleTuner}
                addAssetsDisabled={addAssetsDisabled}
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                shouldRemoveAll={shouldRemoveAll}
                isSubmitting={isSubmitting}
              />
            )}
            {!tunerOpen && (createCollection || editCollection) && (
              <CreateCollectionPanel
                collectionId={collectionId}
                collectionName={collectionName}
                setCollectionName={setCollectionName}
                collectionColor={collectionColor}
                setCollectionColor={setCollectionColor}
                saveAsCollection={saveAsCollection}
                setSaveAsCollection={setSaveAsCollection}
                selectedAssets={selectedAssets}
                handleRemoveAsset={handleRemoveAsset}
                handleTuner={handleTuner}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              />
            )}
            {tunerOpen && (
              <div className="p-4 pb-0 w-1/2 flex flex-col">
                <DriverTuner
                  setTunerOpen={setTunerOpen}
                  tunerItem={tunerItem}
                  handleAddAsset={handleAddAsset}
                  handleRemoveAsset={handleRemoveAsset}
                  handleTunerSubmit={(checked) => {
                    handleTunerSubmit();
                    if (onTunerAccept) {
                      onTunerAccept(checked);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UserDefinedScope;
