import React, { useRef, useEffect, useState, useCallback } from "react";
import { NodeViewContent, NodeViewProps } from "@tiptap/react";
import CodeViewOptions from "./CodeViewOptions";
import { CodeViewOption } from "./CodeViewOptions";
import { clearMermaid, renderMermaid } from "./mermaidUtils";
import { INSTRUCTIONS_ADD_SUGGESTION } from "../extensions/Instructions/useInstructionPoller";
import { AddSuggestionEvent } from "../extensions/Instructions/useInstructionPoller";
import useGetCallId from "../extensions/Instructions/instruction/useGetCallId";
import CopyCodeButton from "./CopyCode";
import LanguageSelect from "./LanguageSelect";
import { Maximize2Icon } from "lucide-react";
import FullScreenDiagramDialog from "./FullScreenDiagramDialog";
import useGetSources from "@/hooks/useGetSources";
import debounce from "lodash/debounce";

interface CodeBlockProps {
  text: string;
  nodeViewProps: NodeViewProps;
}

const CodeBlock: React.FC<CodeBlockProps> = ({ text, nodeViewProps }) => {
  const [codeViewOptionsOpen, setCodeViewOptionsOpen] = useState(false);
  const mermaidRefAnalysis = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const { updateAttributes, node, extension } = nodeViewProps;
  const { analysisDiagram, analysisSummary } = node.attrs;
  const { run } = useGetCallId(nodeViewProps, false, [
    {
      step_type: "default",
      tool_names: [],
      system_prompts: [
        "voice.software_engineer",
        "voice.copy_editor",
        "interface.technical_context_interface",
        "prompts.task.codeblock_syntax_mermaid",
      ],
      iterations: 1,
    },
  ]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const codeRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const isAnalysis = node.attrs.view === "analysis";
  const viewOption = node.attrs.view || "code";
  const [showFullScreen, setShowFullScreen] = useState(false);
  const isRunningRef = useRef(false);
  const { hasSources } = useGetSources();

  const parseAnalysisResult = (result: string) => {
    const mermaidMatch = result.match(/```mermaid\n([\s\S]*?)```/);
    const diagram = mermaidMatch ? mermaidMatch[1].trim() : null;
    const summary = result.replace(/```mermaid\n[\s\S]*?```/, "").trim();
    return { diagram, summary };
  };

  const handleCodeViewOptionSelect = (option: CodeViewOption) => {
    updateAttributes({ view: option });
    setCodeViewOptionsOpen(false);

    // Run analysis when switching to analysis view if text has changed
    if (option === "analysis" && text !== node.attrs.analysisText) {
      runAnalysis();
    }
  };

  const runAnalysis = () => {
    if (isRunningRef.current || !hasSources) return;
    isRunningRef.current = true;
    updateAttributes({
      analysisDiagram: "",
      analysisSummary: "",
      analysisText: "",
    });
    if (mermaidRefAnalysis.current) {
      clearMermaid(mermaidRefAnalysis.current);
    }
    setIsLoading(true);
    run({
      prompt: `Generate a summary and a mermaid diagram (if appropriate) of this code:\n\n${text}`,
      useContext: false,
    });
  };

  // run analysis if the text has changed and we don't have an analysis yet
  useEffect(() => {
    if (!hasSources) return;
    const hasAnalysis = text === node.attrs.analysisText;
    if (!hasAnalysis) {
      runAnalysis();
    }
  }, [hasSources]);

  // Add event listener for suggestion results
  useEffect(() => {
    const handleSuggestion = (event: Event) => {
      const e = event as AddSuggestionEvent;
      if (e.detail.call_id !== node.attrs.callId) return;
      const { diagram, summary } = parseAnalysisResult(e.detail.response);
      updateAttributes({
        analysisDiagram: diagram,
        analysisSummary: summary,
        analysisText: text,
        callId: "",
      });
      isRunningRef.current = false;
      setIsLoading(false);
    };

    document.addEventListener(INSTRUCTIONS_ADD_SUGGESTION, handleSuggestion);
    return () => {
      document.removeEventListener(
        INSTRUCTIONS_ADD_SUGGESTION,
        handleSuggestion
      );
    };
  }, [text, node.attrs.callId]);

  // render the mermaid diagram
  useEffect(() => {
    if (analysisDiagram && mermaidRefAnalysis.current) {
      renderMermaid(analysisDiagram, mermaidRefAnalysis.current);
    }
  }, [analysisDiagram]);

  return (
    <>
      <div
        className="relative node-codeBlock"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          className={`items-center absolute top-3 right-3 bg-neutral-900/20 backdrop-blur-sm z-10 ${isHovered ? "flex" : "hidden"}`}
        >
          {isAnalysis && analysisDiagram && (
            <button
              className="p-1.5 rounded text-neutral-300 hover:bg-neutral-100/15 active:bg-neutral-100/10"
              onClick={() => {
                setShowFullScreen(true);
              }}
            >
              <Maximize2Icon className="h-4 w-4" />
            </button>
          )}
          {hasSources && (
            <CodeViewOptions
              codeViewOptionsOpen={codeViewOptionsOpen}
              view={viewOption}
              setCodeViewOptionsOpen={setCodeViewOptionsOpen}
              handleCodeViewOptionSelect={handleCodeViewOptionSelect}
            />
          )}
          <CopyCodeButton text={text} />
          <LanguageSelect
            defaultLanguage={node.attrs.language}
            updateAttributes={updateAttributes}
            extension={extension}
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
          />
        </div>
        <div>
          <pre ref={codeRef}>
            {viewOption === "code" && (
              <NodeViewContent as="code" spellCheck="false" />
            )}
            {isAnalysis && isLoading && <div>Generating...</div>}
            {isAnalysis && !isLoading && (
              <div className="mb-6">{analysisSummary}</div>
            )}
            <div
              ref={mermaidRefAnalysis}
              className="mermaid flex justify-center items-center relative"
              style={{
                display: isAnalysis ? "block" : "none",
              }}
            ></div>
          </pre>
        </div>
      </div>

      <FullScreenDiagramDialog
        showFullScreen={showFullScreen}
        setShowFullScreen={setShowFullScreen}
        diagram={analysisDiagram}
        summary={analysisSummary}
        text={text}
      />
    </>
  );
};

export default CodeBlock;
