import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { Plugin } from "prosemirror-state";

import Instruction from "./instruction/Container";

export const InstructionPromptNodeName = "smartInstruction";
export const InstructionPromptTagName = "smart-instruction";
const smartInstrucionBegin = "@startSmartInstruction";
const smartInstrucionEnd = "@endSmartInstruction";

const createSmartInstructionNode = (name: string) => {
  return Node.create({
    name: name,
    addOptions() {
      return {
        disabled: false,
      };
    },
    group: "block",

    atom: true,

    addAttributes() {
      return {
        disabled: {
          default: this.options.disabled,
        },
        prompt: {
          default: "",
        },
        identifier: {
          default: "",
        },
        isCreated: {
          default: true,
        },
        isSaved: {
          default: false,
        },
        addMore: {
          default: false,
        },
        isLoading: {
          default: false,
        },
        hasLLMContent: {
          default: false,
        },
        callId: {
          default: "",
        },
        generatedText: {
          default: "",
        },
        error: {
          default: "",
        },
        references: {
          default: [],
        },
        sources: {
          default: [],
        },
      };
    },

    parseHTML() {
      return [
        {
          tag: InstructionPromptTagName,
        },
      ];
    },

    renderHTML({ HTMLAttributes }) {
      return [InstructionPromptTagName, mergeAttributes(HTMLAttributes)];
    },

    addNodeView() {
      return ReactNodeViewRenderer(Instruction);
    },
    addProseMirrorPlugins() {
      return [
        new Plugin({
          appendTransaction(transactions, oldState, newState) {
            let tr = newState.tr;
            let modified = false;
            let offset = 0;

            newState.doc.descendants((node, pos) => {
              const text = node.textContent.trim();
              const isSmartInstruction =
                text.startsWith(smartInstrucionBegin) &&
                text.endsWith(smartInstrucionEnd);
              if (node.type.name === "codeBlock" && isSmartInstruction) {
                const prompt = text
                  .replace(smartInstrucionBegin, "")
                  .replace(smartInstrucionEnd, "");
                const smartInstructionNode =
                  newState.schema.nodes.smartInstruction.create(
                    { prompt },
                    newState.schema.text(prompt)
                  );
                tr = tr.replaceWith(
                  pos + offset,
                  pos + offset + node.nodeSize,
                  smartInstructionNode
                );
                offset += smartInstructionNode.nodeSize - node.nodeSize;
                modified = true;
              }
            });

            return modified ? tr : null;
          },
        }),
      ];
    },
  });
};

export default createSmartInstructionNode;
